import { TableCustom } from 'components/common/Tables/TableCustom'
import { editColumn } from 'components/common/EditColumn/EditColumn'
import { COMMUNITIES_DETAILED_COLUMNS } from 'settings/columns.settings'
import { ManageViewModal } from 'components/Manage/ManageViewModal/ManageViewModal'
import { manageInfoColumn } from 'components/Manage/ManageInfoColumn/ManageInfoColumn'
import { PostRequestCommunityModal, RequestCommunityModal } from '../RequestCommunityModal/RequestCommunityModal'
import { ShareModal } from 'components/common/ShareModal/ShareModal'
import { TableTags } from 'components/common/TableTags/TableTags'

import { Spin, Tabs } from 'antd'
import { observer } from 'mobx-react-lite'
import { Card } from 'components/common'
import { useEffect } from 'react'
import { useEffectOnce } from 'react-use'

import { store } from 'store'

export const ManageCommunitiesTable = observer(() => {
  const { manageCommunitiesStore, loaderStore, userStore, usersStore } = store
  const {
    paginationTableTab1,
    paginationTableTab2,
    paginationTableTab3,
    updatePagination,
    viewModalData,
    isViewModalOpen,
    setIsViewModalOpen,
    openView,
    isRequestModalOpen,
    setIsRequestModalOpen,
    submitCommunity,
    isPostRequestModalOpen,
    setIsPostRequestModalOpen,
    openModal,
    isShareModalOpen,
    setIsShareModalOpen,
    fetchData,
    tableTabData1,
    tableTabData2,
    tableTabData3,
    setUserID,
    getLightTags,
    lightTags,
    createTag,
    actionTag,
    shareItem,
  } = manageCommunitiesStore

  const { users } = usersStore

  const { userId } = userStore

  useEffect(() => {
    setUserID(userId)
  }, [userId])

  useEffect(() => {
    fetchData({ tab: 1 })
  }, [paginationTableTab1])

  useEffect(() => {
    fetchData({ tab: 2 })
  }, [paginationTableTab2])

  useEffect(() => {
    fetchData({ tab: 3 })
  }, [paginationTableTab3])

  useEffectOnce(() => {
    getLightTags()
    usersStore.fetchUsers()
  })

  const tableColumn = [
    manageInfoColumn({
      onClickView: (id: number) => {
        openView(id)
      },
    }),
    {
      title: 'tags',
      dataIndex: 'col2',
      render: (col: any) => (
        <TableTags
          actionTag={actionTag}
          itemData={{ type: 'community', id: col.itemID }}
          createTag={createTag}
          tags={col.tags}
          allTags={lightTags}
        />
      ),
      width: '28%',
      align: 'left',
    },
    ...COMMUNITIES_DETAILED_COLUMNS,
    editColumn({ type: 'community', openModal }),
  ]

  return (
    <section className='c-full'>
      <Card variant='1'>
        <Spin size='large' spinning={loaderStore.isLoadingCommunities || loaderStore.isLoadingTags}>
          <Tabs className='c-tabs'>
            <Tabs.TabPane tab={`My Communities`} key='1'>
              <TableCustom
                data={tableTabData1}
                columns={tableColumn}
                pagination={paginationTableTab1}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 1, pagination })
                }}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={`What's New`} key='2'>
              <TableCustom
                data={tableTabData2}
                columns={tableColumn}
                pagination={paginationTableTab2}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 2, pagination })
                }}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={`All`} key='3'>
              <TableCustom
                data={tableTabData3}
                columns={tableColumn}
                pagination={paginationTableTab3}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 3, pagination })
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </Card>
      <ManageViewModal
        openModal={openModal}
        type='community'
        isLoading={loaderStore.isLoadingCommunities}
        data={viewModalData}
        isVisible={isViewModalOpen}
        setIsModalVisible={setIsViewModalOpen}
      />
      <RequestCommunityModal
        isVisible={isRequestModalOpen}
        setIsModalVisible={setIsRequestModalOpen}
        submitCommunity={submitCommunity}
      />
      <PostRequestCommunityModal isVisible={isPostRequestModalOpen} setIsModalVisible={setIsPostRequestModalOpen} />
      <ShareModal
        isVisible={isShareModalOpen}
        type='community'
        setIsModalVisible={setIsShareModalOpen}
        users={users}
        userID={userId}
        shareItem={shareItem}
      />
    </section>
  )
})
