import { ListPage } from 'components/common/ListPage/ListPage/ListPage'
import { ListPageHeading } from 'components/common/ListPage/ListPageHeading/ListPageHeading'
import { ListPageSection } from 'components/common/ListPage/ListPageSection/ListPageSection'
import { ManageNarrativesTable } from 'components/Manage/ManageNarratives/ManageNarrativesTable/ManageNarrativesTable'

export const ManageNarrativesPage = () => {
  return (
    <ListPage>
      <ListPageHeading title={'Narratives'} linkText={'Add narrative'} redirectPath={'/add-narrative'} />
      <ListPageSection variant='1' content={<ManageNarrativesTable />} />
    </ListPage>
  )
}
