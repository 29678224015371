import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { UtilService } from 'services/Util/Util'

interface Props {
  snippetsData: any
  pagination: {
    current: number
    pageSize: number
  }
}

export const getSnippets = async ({ snippetsData, pagination }: Props) => {
  try {
    let url =
      process.env.REACT_APP_API +
      ROUTES.newNarrativeSnippetsSearch +
      '?' +
      UtilService.generateURLSearchParams({ per_page: pagination.pageSize, page: pagination.current })

    const { data } = await axios.post(url, snippetsData)

    return data
  } catch (error: any) {
    LogService.error({ message: 'snippet search API error', error })
    return { error: error.response?.data?.message }
  }
}
