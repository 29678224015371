import { makeAutoObservable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'

import { IRole, IUser, IUserToSave } from 'models/models'
import { API } from 'api/api'
import { UtilService } from 'services/Util/Util'

export class UserStore {
  userName: string | null = null
  userEmail: string | null = null
  given_name: string | null = null
  family_name: string | null = null
  userTitle: string | null = null
  tenantId: string | null = null
  userId: string | null = null
  subID: string | null = null
  roleId: string | null = null

  currentRole: 'USER' | 'ADMIN' = 'USER'
  users: IUser[] = []
  roles: IRole[] = []

  isDeleteModalVisible: boolean = false
  isAddUserModalVisible: boolean = false

  preDeleteUsername: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  getUsers = async () => {
    const { data, error } = await API.getUsers()
    if (error) return UtilService.openNotification({ type: 'error', message: 'Error', description: error })
    this.setUsers(data)
  }

  getRoles = async () => {
    // const { data, error } = await API.getRoles()
    // if (error) return UtilService.openNotification({ type: 'error', message: 'Error', description: error })
    // this.setRoles(data)
  }

  /*
   * TECH_DEBT
   * Request the user roles endpoint and if we get "Unauthorized" it means we are logged in as USER
   * There is a change that the request could just fail and the user could be in fact an ADMIN
   * but that's why this is considered tech debt.
   * There should be a more elegant way to get the needed info from cognito.
   **/

  checkAndSetCurrentRole = async () => {
    const error = undefined
    //const { error } = await API.getRoles()

    if (error) this.setCurrentRole('USER')
    else this.setCurrentRole('ADMIN')
  }

  saveUser = async (user: IUserToSave) => {
    const { data, error } = await API.saveUser(user)

    if (error || !data) {
      return UtilService.openNotification({
        type: 'error',
        message: 'Error',
        description: error,
      })
    }

    try {
      const role = this.roles.find((item) => item.id === data.roleId)?.role.toLocaleLowerCase() || 'user'
      const { sub: subId, tenantId: accountId, username: name } = data

      await API.saveUserInSnowflake({ subId, accountId, name, role })

      this.addUser(data)
      UtilService.openNotification({ type: 'info', message: 'Success', description: 'User saved succesfully' })
    } catch (error: any) {
      return UtilService.openNotification({
        type: 'error',
        message: 'Error',
        description: error,
      })
    }
  }

  deleteUser = async (userName: string) => {
    const { data, error } = await API.deleteUser(userName)

    if (error) return UtilService.openNotification({ type: 'error', message: 'Error', description: error })

    const index = this.users.findIndex((user) => user.username === userName)
    this.users.splice(index, 1)

    this.setDeleteModal(false)
    UtilService.openNotification({ type: 'info', message: 'Success', description: data })
  }

  get usersTableData() {
    return this.users?.map((user) => ({ ...user, key: uuidv4() }))
  }

  updateUserRole = async ({
    userId,
    username,
    newRoleId,
    oldRoleId,
  }: {
    userId: string
    username: string
    newRoleId: string
    oldRoleId: string
  }) => {
    const newRoleString = this.roles.find((item) => item.id === newRoleId)?.role
    if (!newRoleString) return

    const [{ error }, { error: error1 }] = await Promise.all([
      API.updateUserRole({ username, newRoleId }),
      API.updateUserRolePendService({ userId, roleString: newRoleString }),
    ])

    if (error) {
      const oldRoleString = this.roles.find((item) => item.id === oldRoleId)?.role
      if (!oldRoleString) return
      API.updateUserRolePendService({ userId, roleString: oldRoleString }) // if update unsucessful revert the change on the other api
      return UtilService.openNotification({ type: 'error', message: 'Error', description: error })
    }
    if (error1) {
      await API.updateUserRole({ username, newRoleId: oldRoleId }) // if update unsucessful revert the change on the other api
      return UtilService.openNotification({ type: 'error', message: 'Error', description: error1 })
    }

    // aparently this doesn update the select value because the value prop of the select should be updated
    //TODO: update the code so this works properly
    this.editUser({ username, newRoleId })
  }

  setUser = ({
    userName,
    userEmail,
    given_name,
    family_name,
    tenantId,
    userId,
    roleId,
    subId,
  }: {
    userName: string | null
    userEmail: string | null
    given_name: string | null
    family_name: string | null
    tenantId: string | null
    userId: string | null
    roleId: string | null
    subId: string | null
  }) => {
    this.userName = userName
    this.userEmail = userEmail
    this.given_name = given_name
    this.family_name = family_name
    this.tenantId = tenantId
    this.userId = userId
    this.roleId = roleId
    this.subID = subId
  }

  editUser = ({ username, newRoleId }: { username: string; newRoleId: string }) => {
    this.users.map((user) => {
      if (user.username === username) user.roleId = newRoleId
      return user
    })
  }

  setUserMeta = ({ userTitle }: { userTitle: string }) => (this.userTitle = userTitle)

  setUsers = (users: IUser[]) => (this.users = users)

  addUser = (newUser: IUser) => this.users.push(newUser)

  setRoles = (roles: IRole[]) => (this.roles = roles)

  setUserModal = (visible: boolean) => (this.isAddUserModalVisible = visible)

  setDeleteModal = (visible: boolean) => (this.isDeleteModalVisible = visible)

  setPredeleteUser = (username: string) => (this.preDeleteUsername = username)

  setCurrentRole = (role: 'USER' | 'ADMIN') => (this.currentRole = role)
}
