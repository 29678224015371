import { IVideo } from 'models/models'
import { VideoExtra } from 'components/common/VideoExtra/VideoExtra'
import { Creator } from 'components/common/Creator/Creator'

//TODO: Remove all the ManageInfo and use the manageInfoColumn inside the ManageInfo+.tsx file (also change the name)
export const VIDEOS_SUMMARY_COLUMNS = [
  {
    width: '5%',
  },
  {
    title: 'Impressions',
    dataIndex: 'col2',
    render: (col: IVideo) => <VideoExtra video={col} />,
    width: '30%',
  },
]

export const MANAGE_NARRATIVES_TABLE = [
  {
    title: 'creator',
    dataIndex: 'col3',
    render: (col: any) => <Creator creatorImage={col.creatorImage} creatorName={col.creatorName} />,
    width: '25%',
    align: 'left',
  },
  {
    title: 'Date Created',
    dataIndex: 'col4',
    render: (col: any) => {
      return <div>{col.dateCreated.replaceAll('.', '/')}</div>
    },
    width: '25%',
    align: 'left',
  },
]

export const COMMUNITIES_DETAILED_COLUMNS = [
  {
    title: 'creator',
    dataIndex: 'col3',
    render: (col: any) => <Creator creatorImage={col.creatorImage} creatorName={col.creatorName} />,
    width: '25%',
    align: 'left',
  },
  {
    title: 'Date Created',
    dataIndex: 'col4',
    render: (col: any) => {
      return <div>{col.dateCreated.replaceAll('.', '/')}</div>
    },
    width: '25%',
    align: 'left',
  },
]

export const TAGS_DETAILED_COLUMNS = [
  {
    title: 'Narratives',
    dataIndex: 'col2',
    render: (col: any) => <>{JSON.stringify(col)}</>,
    width: '14%',
    align: 'left',
  },
  {
    title: 'Communities',
    dataIndex: 'col3',
    render: (col: any) => <>{JSON.stringify(col)}</>,
    width: '14%',
    align: 'left',
  },
  {
    title: 'Creators',
    dataIndex: 'col4',
    render: (col: any) => <>{JSON.stringify(col)}</>,
    width: '14%',
    align: 'left',
  },
  {
    title: 'Posts',
    dataIndex: 'col5',
    render: (col: any) => <>{JSON.stringify(col)}</>,
    width: '8%',
    align: 'left',
  },
]
export const TAG_DETAILED_NARRATIVES_COMMUNITIES = [
  {
    title: 'Creator',
    dataIndex: 'col2',
    render: (col: any) => <>{col ? col : ' N/A'}</>,
    width: '20%',
    align: 'left',
  },
  {
    title: 'Date Created',
    dataIndex: 'col3',
    render: (col: any) => {
      return <div>{col}</div>
    },
    width: '20%',
    align: 'left',
  },
]

export const TAG_DETAILED_CREATORS = [
  {
    title: 'Views',
    dataIndex: 'col2',
    render: (col: any) => <>{col ? col : ' N/A'}</>,
    width: '20%',
    align: 'left',
  },
  {
    title: 'Subscribers',
    dataIndex: 'col3',
    render: (col: any) => {
      return <div>{col}</div>
    },
    width: '20%',
    align: 'left',
  },
]
export const TAG_DETAILED_POSTS = [
  {
    title: 'Views',
    dataIndex: 'col2',
    render: (col: any) => <>{col ? col : ' N/A'}</>,
    width: '20%',
    align: 'left',
  },
  {
    title: 'creator',
    dataIndex: 'col3',
    render: (col: any) => {
      return <div>{col}</div>
    },
    width: '20%',
    align: 'left',
  },
  {
    title: 'Risk Score',
    dataIndex: 'col3',
    render: (col: any) => {
      return <div>{col}</div>
    },
    width: '20%',
    align: 'left',
  },
]
