import { Tag, Tooltip } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { ReactComponent as IconCloseTag } from 'assets/images/close-tag.svg'

import { TagsList } from '../TagsList/TagsList'
import { AddTags } from '../AddTags/AddTags'

import { ILightTag } from 'models/models'

import './TableTags.scss'

interface Props {
  tags: string[]
  tagsLimit?: number
  canAdd?: boolean
  allTags?: ILightTag[]
  createTag?: (tagLabel: string, id: string) => void
  itemData?: { type: 'narrative' | 'community'; id: string }
  actionTag?: ({ action, tagId, id }: { action: 'apply' | 'remove'; tagId: string; id: string }) => void
}

export const TableTags = ({ tags, tagsLimit = 3, canAdd = true, allTags, createTag, itemData, actionTag }: Props) => {
  const tooManyTags = tags.length > tagsLimit

  let displayTags = tags
  if (tooManyTags) displayTags = tags.slice(0, tagsLimit - 1)
  let otherTags = tags.slice(tagsLimit - 1, tags.length)

  return (
    <div className='c-table-tags__list'>
      {displayTags?.map((tag: any) => {
        const isLongTag = tag.length > 20

        const keywordElement = (
          <Tag
            className='c-table-tags__list-item'
            closable
            key={uuidv4()}
            closeIcon={<IconCloseTag className='c-table-tags__list__close-icon' />}
            onClose={() => {
              const tagId = allTags?.filter((el: any) => el.label === tag)[0]?.id
              if (itemData?.id && actionTag && tagId)
                actionTag({
                  action: 'remove',
                  tagId: tagId,
                  id: itemData?.id,
                })
            }}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        )

        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {keywordElement}
          </Tooltip>
        ) : (
          keywordElement
        )
      })}
      {tooManyTags && <TagsList allTags={allTags} contentId={itemData?.id} actionTag={actionTag} tags={otherTags} />}
      {canAdd && (
        <AddTags
          actionTag={actionTag}
          contentId={itemData?.id}
          createTag={createTag}
          usedTags={tags}
          allTags={allTags}
        />
      )}
    </div>
  )
}
