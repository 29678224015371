import { store } from 'store'
import { useEffectOnce } from 'react-use'
import { observer } from 'mobx-react-lite'
import { Spin } from 'antd'

import { Card } from 'components/common'
import { MonitorListItem } from '../MonitorListItem/MonitorListItem'
import VirtualList from 'rc-virtual-list'
import { MonitorListFilter } from '../MonitorListFilter/MonitorListFilter'

import './MonitorTagsList.scss'

export const MonitorTagsList = observer(() => {
  const { monitorTags, loaderStore } = store
  const {
    fetchListItems,
    listItems,
    openedItemData,
    openItem,
    listFilter,
    avableFilters,
    setListFilter,
    liteCommunities,
  } = monitorTags
  const { isLoadingTags, isLoadingStats } = loaderStore

  const ContainerHeight = 907

  useEffectOnce(() => {
    fetchListItems()
  })

  return (
    <Card variant='1'>
      <div className='c-monitor-tag-list'>
        <div className='c-monitor-tag-list__header'>
          <div className='c-monitor-tag-list__header-type'>Tags</div>
          <div className='c-monitor-tag-list__header-filter'>
            <MonitorListFilter selectedFilter={listFilter} setFilter={setListFilter} elements={avableFilters} />
          </div>
        </div>
        <Spin spinning={isLoadingTags}>
          <VirtualList
            className='c-monitor-tag-list__items'
            data={listItems}
            height={ContainerHeight}
            itemHeight={75}
            itemKey='id'
            onScroll={(e) => {
              if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight)
                console.log('TODO:load more tags')
            }}>
            {(item) => {
              const isSelected = item.id === openedItemData?.id
              return (
                <div>
                  <MonitorListItem
                    liteCommunities={liteCommunities}
                    key={item.id}
                    data={item}
                    isLoading={isLoadingStats}
                    isSelected={isSelected}
                    advancedData={isSelected ? openedItemData : undefined}
                    openItem={openItem}
                  />
                </div>
              )
            }}
          </VirtualList>
        </Spin>
      </div>
    </Card>
  )
})
