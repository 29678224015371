import { makeObservable, observable } from 'mobx'
import { ManageStore } from './manage.store'

export class ManageNarrativesStore extends ManageStore {
  storeType: 'tag' | 'community' | 'narrative' = 'narrative'

  avableShareUsers: { name: string; email: string; role: 'viewer' | 'owner'; canBeEdited: boolean }[] = [
    { name: 'Bessie Cooper', email: 'bessie.cooper@outlook.com', role: 'owner', canBeEdited: false },
    { name: 'Jane Cooper', email: 'jane.cooper@outlook.com', role: 'viewer', canBeEdited: true },
  ]

  get filterTableTab1() {
    return `owner_id:eq:${this.userID}`
  }
  get filterTableTab2() {
    const ms = new Date().getTime() - 24 * 60 * 60 * 1000
    const date = new Date(ms).toISOString()
    return `created:gt:${date}`
  }
  get filterTableTab3() {
    return ``
  }

  constructor() {
    super()
    makeObservable(this, { avableShareUsers: observable })
  }
}
