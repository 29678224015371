import { v4 as uuidv4 } from 'uuid'
import millify from 'millify'
import dayjs from 'dayjs'

import { ICommunity, INarrative, ITag, IVideo } from 'models/models'

function getVideos(source: IVideo[]) {
  return source?.map((item: IVideo) => ({
    col1: {
      ...item,
      impressionsMillify: millify(item.impressions || 0),
      uploadDate: dayjs(item.uploadDate).format('MMM DD, YYYY'),
    },
    col2: {
      ...item,
      channelViewsMillify: millify(item.channelViews || 0),
      channelSubsMillify: millify(item.channelSubs || 0),
    },
    key: uuidv4(),
  }))
}

function getNarratives(source: INarrative[]) {
  return source?.map((item: INarrative) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
      isDraft: item.isDraft,
    },
    col2: { tags: item?.tags, itemID: item.id },
    col3: {
      creatorImage: item.creatorImage,
      creatorName: item.creatorName,
    },
    col4: {
      dateCreated: item.dateCreated,
    },

    editColumn: {
      id: item.id,
    },
    key: uuidv4(),
  }))
}

const getCommunities = (source: ICommunity[]) => {
  return source?.map((item: ICommunity) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: { tags: item?.tags, itemID: item.id },
    col3: {
      creatorImage: item.creatorImage,
      creatorName: item.creatorName,
    },
    col4: {
      dateCreated: item.dateCreated,
    },

    editColumn: {
      id: item.id,
    },
    key: uuidv4(),
  }))
}

const getTags = (source: ITag[]) => {
  return source?.map((item: ITag) => ({
    col1: {
      id: item.id,
      name: item.name,
      followingStatus: item.following,
    },
    col2: item.narrativesCount,
    col3: item.communitiesCount,
    col4: item.creatorsCount,
    col5: item.postsCount,
    col6: { followingStatus: item.following, tagID: item.id },
    editColumn: {
      id: item.id,
    },
    key: uuidv4(),
  }))
}

type tableType = 'tag' | 'narrative' | 'community' | 'videos'

export function getTableData({ source, table }: { source: any; table: tableType }) {
  switch (table) {
    case 'tag':
      return getTags(source)

    case 'narrative':
      return getNarratives(source)

    case 'community':
      return getCommunities(source)

    case 'videos':
      return getVideos(source)
  }
}
export function getTagDetailsData({ source, type }: { source: any; type: string }) {
  switch (type) {
    case 'narrative':
      return getDetailedNarrativeTags(source)
    case 'community':
      return getDetailedCommunityTags(source)
    case 'post':
      return getDetailedPostTags(source)
    case 'creator':
      return getDetailedCreatorTags(source)
  }
}

const getDetailedNarrativeTags = (source: any[]) => {
  return source?.map((item: any) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'narrative' },
  }))
}

const getDetailedCommunityTags = (source: any[]) => {
  return source?.map((item: any) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'community' },
  }))
}
const getDetailedPostTags = (source: any[]) => {
  return source?.map((item: any) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'narrative' },
  }))
}
const getDetailedCreatorTags = (source: any[]) => {
  return source?.map((item: any) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'narrative' },
  }))
}
