import millify from 'millify'

import { ISourceData } from 'models/models'
import { UtilService } from './Util'

export function getSourcesDataPretty({ data }: { data: ISourceData[] }) {
  if (!data || !data.length) return []

  return data.map((item: any) => ({
    ...item,
    impressionsPretty: millify(item.impressions || 0),
    mentionsPretty: millify(item.mentions || 0),
    numVideosPretty: millify(item.numVideos || 0),
    value: item.impressions,
    color: UtilService.getPlatformColor(item.platform),
  }))
}
