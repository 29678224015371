import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { route } from 'models/models'
import { LogService } from 'services/Log/Log'
import { UtilService } from 'services/Util/Util'

export async function get({
  route,
  page,
  pageSize,
  startDateISO,
  endDateISO,
  selectedSourcesFilter,
  selectedCreatorsFilter,
  selectedCommunitiesFilter,
  selectedNarrativesFilter,
  isPromise = false,
  search,
  getError,
  id,
  filter,
  customAPI,
  ids,
  customProps,
}: {
  route: route
  page?: number
  pageSize?: number
  startDateISO?: string
  endDateISO?: string
  selectedSourcesFilter?: string[]
  selectedCreatorsFilter?: string[]
  selectedCommunitiesFilter?: string[]
  selectedNarrativesFilter?: string[]
  dataFilter?: string[]
  id?: number | string | null
  isPromise?: boolean
  search?: string
  getError?: boolean
  filter?: string | null
  customAPI?: string
  ids?: string
  customProps?: { [customProp: string]: string }
}): Promise<any> {
  try {
    const useSourcesQuery = selectedSourcesFilter?.length !== 0 && selectedSourcesFilter?.[0] !== 'All'
    const useCreatorsQuery =
      selectedCreatorsFilter?.length !== 0 && selectedCreatorsFilter?.[0] !== 'All' && !route.includes('BubbleChart')
    const useCommunitiesQuery = selectedCommunitiesFilter?.length !== 0 && selectedCommunitiesFilter?.[0] !== 'All'
    const useNarrativesQuery = selectedNarrativesFilter?.length !== 0 && selectedNarrativesFilter?.[0] !== 'All'

    const searchParams = UtilService.generateURLSearchParams({
      search,

      page: page,
      per_page: pageSize,

      q: filter,

      startDate: startDateISO,
      endDate: endDateISO,

      ids,

      ...customProps,

      ...(useSourcesQuery && { source: selectedSourcesFilter }),
      ...(useCreatorsQuery && { creator: selectedCreatorsFilter }),
      ...(useCommunitiesQuery && { communities: selectedCommunitiesFilter }),
      ...(useNarrativesQuery && { narratives: selectedNarrativesFilter }),
    })

    const urlId = id ? `/${id}` : ''
    const customApi = customAPI ? customAPI : ''

    const endpoint = `${customApi}${ROUTES[route as keyof typeof ROUTES]}${urlId}${
      searchParams ? '?' : ''
    }${searchParams}`

    if (isPromise) {
      return axios({ method: 'get', url: `${endpoint}` })
    } else {
      const { data } = await axios.get(`${endpoint}`)

      return { data, total: data.total_count }
    }
  } catch (error) {
    LogService.error({ message: 'get API error', error })

    if (getError) throw error

    return { data: null, total: 0 }
  }
}
