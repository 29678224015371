import { Divider, Modal, Spin } from 'antd'
import { ReactNode } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'

import './ActionModal.scss'
export const ActionModal = ({
  title,
  visible,
  onOk,
  onCancel,
  children,
  isLoading = false,
  okText,
}: {
  title: string
  visible: boolean
  onOk: (val: any) => void
  onCancel: (val: any) => void
  children: ReactNode
  isLoading?: boolean
  okText: string
}) => {
  return (
    <Modal
      width={800}
      bodyStyle={{ maxHeight: '500px', overflow: 'auto' }}
      title={title}
      okText={okText}
      wrapClassName='c-action-modal'
      visible={visible}
      closable
      centered
      closeIcon={<CloseCircleOutlined style={{ width: '25px', height: '25px' }} />}
      onOk={onOk}
      onCancel={onCancel}>
      <Spin spinning={isLoading}>
        <Divider plain /> {children} <Divider plain />
      </Spin>
    </Modal>
  )
}
