export const getVideoURL = ({ platform, videoId }: { platform?: string; videoId?: string }) => {
  let videoSrc = ''
  switch (platform) {
    case 'YouTube':
      videoSrc = `https://www.youtube.com/embed/${videoId}`
      break
    case 'BitChute':
      videoSrc = `https://www.bitchute.com/embed/${videoId?.replace('BitChute|', '')}/`
      break
    case 'Rumble':
      videoSrc = `https://rumble.com/embed/v6uz6v/?pub=${videoId?.replace('https://rumble.com', '')}/`
      break
  }
  return videoSrc
}
