import { Form, Input, Alert } from 'antd'

import { Button } from 'components/common/Button/Button'
import { ResetPasswordTitle } from '../ResetPasswordTitle/ResetPasswordTitle'

import './ResetPassStage1.scss'
import { SignInLink } from '../SignInLink/SignInLink'

interface Props {
  info: { message: string; name?: string; code?: string; type: 'success' | 'error' } | null
}

export const ResetPassStage1 = ({ info }: Props) => {
  return (
    <>
      <ResetPasswordTitle>Reset Password</ResetPasswordTitle>

      {info && <Alert message={info.message} type={info.type} />}

      <p className='c-reset-pass-stage1__helper-text'>
        Enter your email address below. You’ll receive a confirmation code that will be used in the next step.
      </p>

      <Form.Item label='Email' name='userEmail' rules={[{ required: true, message: 'Please input your email!' }]}>
        <Input placeholder='Your email address' type='email' />
      </Form.Item>

      <Button type='primary-2' htmlType='submit'>
        Send confirmation code to email
      </Button>

      <SignInLink />
    </>
  )
}
