import { ListPage } from 'components/common/ListPage/ListPage/ListPage'
import { ListPageHeading } from 'components/common/ListPage/ListPageHeading/ListPageHeading'
import { ListPageSection } from 'components/common/ListPage/ListPageSection/ListPageSection'
import { ManageCommunitiesTable } from 'components/Manage/ManageCommunities/ManageCommunitiesTable/ManageCommunitiesTable'

import { store } from 'store'

export const ManageCommunitiesPage = () => {
  return (
    <ListPage>
      <ListPageHeading
        button
        title={'Communities'}
        linkText={'Request community'}
        onClick={() => {
          store.manageCommunitiesStore.setIsRequestModalOpen(true)
        }}
      />
      <ListPageSection variant='1' content={<ManageCommunitiesTable />} />
    </ListPage>
  )
}
