import { Link as RouterLink } from 'react-router-dom'

import './Button.scss'

export const Link = ({
  type,
  children,
  ...rest
}: {
  type: 'primary-1' | 'primary-2'
  children?: React.ReactNode
  [x: string]: any
}) => {
  return (
    <RouterLink to={rest?.to ? rest.to : '#'} {...rest} className={`custom-button--${type}`}>
      {children}
    </RouterLink>
  )
}
