import axios from 'axios'

interface Props {
  tagId: string
  type: 'narrative' | 'community' | 'post' | 'creator'
  id: string
  action: 'remove' | 'apply'
}

export const addOrRemoveTag = async ({ tagId, type, id, action }: Props) => {
  const data = await axios.post(`/tag/${tagId}/action`, { entity_type: type, entity_id: id, action })
  return data
}
