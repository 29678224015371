import { Card } from 'components/common'

export function PrivacyPage(): JSX.Element {
  return (
    <div className='c-page'>
      <Card variant='1'>
        <h1>Privacy Policy</h1>
      </Card>
    </div>
  )
}
