import { Table } from 'antd'

import './Table.scss'

interface Props {
  data: any
  columns: any
  onTableChange?: any
  [key: string]: any
}

export const TableCustom = ({ data, columns, onTableChange, pagination }: Props): JSX.Element => {
  return (
    <Table onChange={onTableChange} columns={columns} dataSource={data} pagination={pagination} className='c-table' />
  )
}
