import axios from 'axios'

const formatDate = (ms: number) => {
  let date = new Date(ms)

  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  return `${year}-${month + 1}-${day}`
}

export const loadFeed = (page: number, days?: number, narratives?: number[], communities?: number[]) => {
  let currentDate = new Date().getTime()
  let pastDate = new Date(currentDate - 86400000 * (days || 90)).getTime()

  const endpoint =
    process.env.REACT_APP_SEARCH_API +
    `feeds?q=start_date:gte:${formatDate(pastDate)},end_date:lte:${formatDate(currentDate)}&per_page=50&page=` +
    page
  return axios.post(endpoint, { narratives, communities })
}
