import { MonitorStore } from './monitor.store'

export class MonitorTagsStore extends MonitorStore {
  storeType: 'tag' | 'community' | 'narrative' = 'tag'

  avableFilters = {
    impresions: ['Max impressions', 'Min impressions'],
    timeSpan: ['Yesterday', 'Last 7 days', 'Last 30 days', 'Last 90 days'],
    status: ['Followed', 'Unfollowed', 'All tags'],
  }

  listFilter: {
    impresions: 'Max impressions' | 'Min impressions'
    timeSpan: 'Yesterday' | 'Last 7 days' | 'Last 30 days' | 'Last 90 days'
    status: 'Followed' | 'Unfollowed' | 'All tags'
  } = { impresions: 'Max impressions', timeSpan: 'Yesterday', status: 'All tags' }

  // constructor() {
  //   super()
  // }
}
