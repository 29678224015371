import { Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useState } from 'react'

import './RequestCommunityModal.scss'

interface Props {
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
  submitCommunity: (commName: string) => void
}

export const RequestCommunityModal = ({ isVisible, setIsModalVisible, submitCommunity }: Props) => {
  const [newCommunity, setNewCommunity] = useState('')
  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      title={'Request a new community'}
      wrapClassName='c-request-modal'
      visible={isVisible}
      closable
      centered
      onOk={() => {
        submitCommunity(newCommunity)
        setNewCommunity('')
      }}
      onCancel={() => setIsModalVisible(false)}
      okText='Submit'>
      <div className='c-request-modal__centered-text-wraper'>
        <p className='c-request-modal__centered-text'>
          The Pendulum team can help you create additional Communities to better fit your analytical process.
        </p>
      </div>
      <p className='c-request-modal__input-text'>Briefly describe your request</p>
      <Input.TextArea
        value={newCommunity}
        onChange={(e) => {
          setNewCommunity(e.target.value)
        }}
        className='c-request-modal__input'
        placeholder='What you’re looking for in the new community?'
        autoSize={{ minRows: 5, maxRows: 5 }}
      />
    </Modal>
  )
}

interface Props2 {
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
}

export const PostRequestCommunityModal = ({ isVisible, setIsModalVisible }: Props2) => {
  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      title={'Your request has been received!'}
      wrapClassName='c-post-request-modal'
      visible={isVisible}
      closable
      centered
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
      cancelText='Close'>
      <p>Our Customer lead will contact you within 48 hours.</p>
    </Modal>
  )
}
