import { useEffectOnce } from 'react-use'
import { Divider, List, Spin } from 'antd'
import VirtualList from 'rc-virtual-list'
import { observer } from 'mobx-react-lite'
import { v4 as uuidv4 } from 'uuid'
import millify from 'millify'
import { store } from 'store'
import { useEffect, useState } from 'react'

import { Card } from 'components/common'
import { ISnippet } from 'models/models'
import { MonitorSnippet } from '../MonitorSnippet/MonitorSnippet'
import { MonitorListFilter } from '../MonitorListFilter/MonitorListFilter'

import './MonitorSnippetList.scss'

export const MonitorSnippetList = observer(() => {
  const { monitorTags, loaderStore } = store
  const {
    snippets,
    showSurprise,
    fetchSurpriseVideos,
    avableSnippetsFilters,
    snippetsFilter,
    setSnippetsFilter,
    totalSnippets,
    liteCommunities,
  } = monitorTags
  const { isLoadingCreators, isLoadinFeed } = loaderStore
  const [processedSnippets, setProcessedSnippets] = useState<ISnippet[]>([])

  const ContainerHeight = 875

  useEffect(() => {
    let startIndex = processedSnippets.length
    const editedSnippets: ISnippet[] = []

    snippets.slice(processedSnippets.length, snippets.length).forEach((snippet, index) => {
      editedSnippets.push(snippet)
      if ((index + startIndex + 1) % 50 === 0) {
        editedSnippets.push({
          type: 'surprise',
          id: uuidv4(),
          text: '',
          views: 0,
          viewsMilify: '0',
          date: '',
          channelId: '',
          author: { channel_title: '', channel_thumb_url: '', followers_count: 0, view_count: 0, communities: [] },
          sourceLink: '',
          sourcePlatform: 'Rumble',
          riskScore: 0,
        })
      }
    })

    setProcessedSnippets(editedSnippets)
  }, [snippets])

  useEffectOnce(() => {
    fetchSurpriseVideos()
  })

  return (
    <Card variant='1' key={uuidv4()}>
      <div className='c-snippets-list'>
        <div className='c-snippets-list-head'>
          <div className='c-snippets-list__text'>
            Feed{' '}
            <span className='c-snippets-list__results'>({totalSnippets ? millify(totalSnippets) : 0} results)</span>
          </div>
          <MonitorListFilter
            elements={avableSnippetsFilters}
            selectedFilter={snippetsFilter}
            setFilter={setSnippetsFilter}
          />
        </div>
        <Divider />
        <Spin spinning={isLoadingCreators || isLoadinFeed}>
          <List>
            <VirtualList
              className='c-snippet-list__list'
              data={processedSnippets}
              height={ContainerHeight}
              itemHeight={220}
              itemKey='id'
              onScroll={(e) => {
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight)
                  console.log('load more snippets')
              }}>
              {(item) => {
                return (
                  <div>
                    <MonitorSnippet snippet={item} communities={liteCommunities} showSurprise={showSurprise} />
                  </div>
                )
              }}
            </VirtualList>
          </List>
        </Spin>
      </div>
    </Card>
  )
})
