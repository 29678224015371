import { Modal } from 'antd'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import './DefineNarrativeModals.scss'

interface Props {
  modalToShow: 'notify' | 'workLater' | 'loseChanges' | undefined
  closeModal: () => void
}

export const DefineNarrativeModals = ({ modalToShow, closeModal }: Props) => {
  const navigate = useNavigate()
  const modals = {
    notify: {
      title: 'You have activated a new narrative',
      text: 'Your narrative is being processed, this could take up to 1 hour. When it is complete, you will receive an email with a notification.',
      handleOk: () => {
        return navigate('/manage/narratives', { replace: true })
      },
      okText: 'Close',
      cancelText: null,
      handleCancel: closeModal,
    },
    workLater: {
      title: 'Continue work later',
      text: 'Your narrative is saved under Narratives -> Inactive.',
      handleOk: () => {
        return navigate('/manage/narratives', { replace: true })
      },
      okText: 'Go to Narratives page',
      cancelText: null,
      handleCancel: closeModal,
    },
    loseChanges: {
      title: 'You have unsaved changes',
      text: 'Would you like to save your changes before leaving the narrative creation?',
      /* Ok and Cancel Are flipped here just to be like in the design*/
      handleOk: closeModal,
      okText: 'Save',
      cancelText: 'Don`t save',
      handleCancel: () => {
        return navigate('/manage/narratives', { replace: true })
      },
    },
  }

  let modalData = modalToShow && modals[modalToShow]

  return (
    <>
      <Modal
        className={classNames('c-define-narrative-modals', {
          'c-define-narrative-modals--one-button': !modalData?.cancelText,
        })}
        centered
        closable
        visible={!!modalToShow}
        title={modalData?.title}
        children={modalData?.text}
        onOk={modalData?.handleOk}
        onCancel={modalData?.handleCancel}
        okText={modalData?.okText}
        okType={'primary'}
        cancelText={modalData?.cancelText}
        afterClose={closeModal}
      />
    </>
  )
}
