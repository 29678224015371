import { Dropdown, Input, Menu, Modal } from 'antd'
import { ReactComponent as SearchIcon } from 'assets/images/icon-search.svg'
import { ReactComponent as DropdownArrow } from 'assets/images/icon-dropmenu-wider.svg'
import { v4 as uuidv4 } from 'uuid'
import './ShareModal.scss'
import { IUsers } from 'models/models'
import { useState } from 'react'

interface Props {
  type: 'narrative' | 'community' | 'tag'
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
  users: IUsers[]
  userID: string | null
  shareItem: (userIds: string[]) => void
}

export const ShareModal = ({ type, isVisible, setIsModalVisible, users, userID, shareItem }: Props) => {
  const [inputValue, updateInputValue] = useState('')

  const currentUser = users.filter(
    (user) =>
      user.Attributes.filter((attribute) => attribute.Name === 'custom:user_id' && attribute.Value === userID)[0],
  )
  const cleanedUsers = users.filter(
    (user) =>
      user.Attributes.filter((attribute) => attribute.Name === 'custom:user_id' && attribute.Value !== userID)[0],
  )

  const filteredUsers = cleanedUsers
    .filter((user) => {
      const username = user.Username?.toLowerCase()
      const realName = `${user.Attributes.filter((el) => el.Name === 'given_name')[0].Value} 
        ${user.Attributes.filter((el) => el.Name === 'family_name')[0].Value}`?.toLowerCase()

      return username.includes(inputValue.toLowerCase()) || realName.includes(inputValue.toLowerCase())
    })
    .slice(0, 4)

  let usersFiltered = !inputValue ? [...currentUser, ...cleanedUsers] : filteredUsers

  const menu = (
    <Menu>
      <Menu.Item>Owner</Menu.Item>
      <Menu.Item>Viewer</Menu.Item>
      <hr className='c-share-modal__separator' />
      <Menu.Item>Remove</Menu.Item>
    </Menu>
  )

  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      title={`Share this ${type}`}
      wrapClassName='c-share-modal'
      visible={isVisible}
      closable
      centered
      onOk={() => {
        if (!inputValue && !shareItem) return

        const userIds = filteredUsers?.map((user) => {
          return user.Attributes.filter((attribute) => attribute.Name === 'custom:user_id')[0]?.Value
        })
        shareItem(userIds)
      }}
      onCancel={() => setIsModalVisible(false)}
      okText='Send Invitation'
      cancelText='Cancel'>
      <p className='c-share-modal__subtitle'>Invite team members.</p>

      <p className='c-share-modal__label'>Label Text</p>
      <Input
        className='c-share-modal__user-search'
        placeholder='Search by name or email address'
        prefix={<SearchIcon />}
        onChange={(el) => updateInputValue(el.target.value)}
        value={inputValue}
      />
      <div className='c-share-modal__users'>
        {usersFiltered?.slice(0, 4).map((user, index) => {
          const realName = `${user.Attributes.filter((el) => el.Name === 'given_name')[0].Value} 
            ${user.Attributes.filter((el) => el.Name === 'family_name')[0].Value}`

          return (
            <div className='c-share-modal__user' key={uuidv4()}>
              <div className='c-share-modal__user-details'>
                <div className='c-share-modal__user-details--name'>
                  {realName}
                  {index === 0 && !inputValue && <span className='c-share-modal__user-details--you'>(You)</span>}
                </div>
                <div className='c-share-modal__user-details--email'>{user.Username}</div>
              </div>
              <div className='c-share-modal__user-role'>
                <Dropdown overlay={menu} trigger={['click']} disabled={index === 0 && !inputValue}>
                  <div className='c-share-modal__user-role-click'>
                    {/* {user?.role.slice(0, 1).toUpperCase() + user?.role.slice(1, user?.role.length)} */}
                    {(index > 0 || inputValue) && 'Viewer'}
                    {index === 0 && !inputValue ? (
                      'Owner'
                    ) : (
                      <DropdownArrow className='c-share-modal__user-role-arrow' />
                    )}
                  </div>
                </Dropdown>
              </div>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
