import { observer } from 'mobx-react-lite'
import { Divider, Table, Button, Form, Select } from 'antd'
import { Menu, Dropdown } from 'antd'
import { store } from 'store'
import { Card, Heading } from 'components/common'
import { ReactComponent as IconDropdownWhite } from 'assets/images/dropdown-white.svg'

import './Users.scss'
import { ActionModal } from 'components/common/ActionModal/ActionModal'
import { AddUserForm } from './AddUserForm'

export const Users = observer((): JSX.Element => {
  const { userStore, loaderStore } = store
  const {
    usersTableData,
    roles,
    isDeleteModalVisible,
    isAddUserModalVisible,
    setUserModal,
    setDeleteModal,
    setPredeleteUser,
    preDeleteUsername,
    updateUserRole,
  } = userStore
  const { isLoadingUsers } = loaderStore

  const [form] = Form.useForm()
  const { Option } = Select
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'key',
      // sorter: (a: IUser, b: IUser) => a.username.localeCompare(b.username),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'key',
    },
    {
      title: 'Role',
      dataIndex: 'roleId',
      key: 'key',
      render: (roleId: string, data: any) => {
        return (
          <Select
            onChange={async (newId) => {
              await updateUserRole({ userId: data.sub, newRoleId: newId, username: data.username, oldRoleId: roleId })
              setDeleteModal(false)
            }}
            defaultValue={roleId}
            style={{ width: '100%' }}>
            {roles?.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.role}
              </Option>
            ))}
          </Select>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'key',
    },
    {
      dataIndex: 'username',
      key: 'key',
      render: (username: string, data: any) => {
        return (
          <div className='c-edit-column'>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() => {
                      setPredeleteUser(username)
                      setDeleteModal(true)
                    }}>
                    Delete user
                  </Menu.Item>
                  <Menu.Item
                    key='2'
                    onClick={() => {
                      resendTemporaryPassword(data)
                    }}>
                    Resend temporary password
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              className='c-edit-dropdown'
              overlayClassName='c-edit-dropdown__overlay'>
              <button>
                <IconDropdownWhite />
              </button>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const validateAndCreateUser = async () => {
    if (!isLoadingUsers) {
      await form.validateFields()
      const user = {
        username: form.getFieldValue('username'),
        email: form.getFieldValue('email'),
        temporaryPassword: form.getFieldValue('temporaryPassword'),
        roleId: form.getFieldValue('role'),
        groupIds: [],
        notifyUser: form.getFieldValue('notifyUser'),
      }
      await userStore.saveUser(user)
    }
  }

  const resendTemporaryPassword = async (data: any) => {
    if (!isLoadingUsers) {
      const user = {
        username: data.username,
        email: data.email,
        roleId: data.roleId,
        groupIds: data.groupIds,
        resendPassword: true,
      }
      await userStore.saveUser(user)
    }
  }
  const deleteUser = async () => {
    if (!isLoadingUsers) {
      userStore.deleteUser(preDeleteUsername)
    }
  }

  return (
    <Card className='c-users u-p-t-32' variant='1'>
      <section className='c-users__heading'>
        <Heading level='1' variant='2' className='u-m-b-8'>
          Manage users
        </Heading>

        <Button
          className='c-users__add'
          onClick={() => {
            setUserModal(true)
          }}>
          + Add new user
        </Button>
      </section>

      <Divider plain />

      <Table columns={columns} dataSource={usersTableData} pagination={false} loading={isLoadingUsers} />

      {/*add user modal*/}
      <ActionModal
        title='Add new user'
        okText='Save'
        visible={isAddUserModalVisible}
        isLoading={isLoadingUsers}
        onOk={async () => {
          try {
            await validateAndCreateUser()
            form.resetFields()
          } catch (e) {}
        }}
        onCancel={() => {
          setUserModal(false)
        }}>
        <AddUserForm form={form} roles={roles} />
      </ActionModal>

      {/*delete user modal*/}
      <ActionModal
        title='Delete user'
        okText='Yes, delete user'
        visible={isDeleteModalVisible}
        onOk={deleteUser}
        onCancel={() => {
          setDeleteModal(false)
        }}
        isLoading={isLoadingUsers}>
        <div className='c-users__remove-modal-div'>
          <h1>Are you sure?</h1>
          <p>This action cannot be undone.</p>
        </div>
      </ActionModal>
    </Card>
  )
})
