import { Menu } from 'antd'
import { Auth } from 'aws-amplify'
import { observer } from 'mobx-react-lite'
import { useLocation, Link } from 'react-router-dom'

import { ReactComponent as IconDropdownMenu } from 'assets/images/icon-dropmenu.svg'
import { ReactComponent as IconPendulumLogo } from 'assets/images/pendulum-logo-2.svg'

import { store } from 'store'

import './Navigation.scss'

export const Navigation = observer((): JSX.Element => {
  const { userStore } = store
  const { userName } = userStore

  const location = useLocation()
  let selectedKeys: string[] = ['1']

  if (location.pathname.includes('/monitor')) selectedKeys = ['1']
  else if (location.pathname.includes('/manage')) selectedKeys = ['2']
  else if (location.pathname.includes('/analyse')) selectedKeys = ['3']

  async function signOut() {
    await Auth.signOut()
    userStore.setUser({
      given_name: null,
      family_name: null,
      userName: null,
      userEmail: null,
      tenantId: null,
      userId: null,
      roleId: null,
      subId: null,
    })
  }

  return (
    <nav className='c-navigation'>
      <div className='c-navigation__container'>
        <Link to='/manage' className='c-navigation__logo'>
          <IconPendulumLogo />
        </Link>

        <Menu
          mode='horizontal'
          selectedKeys={selectedKeys}
          triggerSubMenuAction='click'
          className='c-navigation__buttons'>
          <Menu.Item key='1'>
            <Link to='/monitor'>Monitor</Link>
          </Menu.Item>

          <Menu.Item key='2'>
            <Link to='/manage'>Manage</Link>
          </Menu.Item>

          <Menu.Item key='3'>
            <Link to='/analyse'>Analyse</Link>
          </Menu.Item>
        </Menu>
        <Menu mode='horizontal' className='c-navigation__submenu' triggerSubMenuAction={'click'}>
          <Menu.SubMenu
            icon={<IconDropdownMenu />}
            key='SubMenu'
            title={userName}
            popupClassName='c-navigation__submenu-popup'
            popupOffset={[-60, 18]}>
            <Menu.Item key='SubMenu1'>
              <Link to='/settings/profile'>Settings</Link>
            </Menu.Item>

            <Menu.Item key='SubMenu3' onClick={signOut}>
              Sign out
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    </nav>
  )
})
