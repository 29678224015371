import { getRandomColor } from './getRandomColor'

export const getPlatformColor = (platform: 'BitChute' | 'Rumble' | 'YouTube' | 'Podcast') => {
  let color = ''

  switch (platform) {
    case 'YouTube':
      color = '#3F3E3E'
      break

    case 'BitChute':
      color = '#CCA553'
      break

    case 'Rumble':
      color = '#B7B7B7'
      break

    case 'Podcast':
      color = '#EFE3CB'
      break

    default:
      color = getRandomColor()
  }

  return color
}
