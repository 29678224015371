import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router-dom'

import { store } from 'store'

interface Props {
  element: JSX.Element
  [x: string]: any
}

export const PrivateRoute = observer(({ element, ...rest }: Props): JSX.Element => {
  const { userStore } = store
  const { userName } = userStore

  return userName ? element : <Navigate replace to='/signin' />
})
