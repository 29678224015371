import { Spin, Tabs } from 'antd'
import { Card } from 'components/common/Card/Card'
import { TableCustom } from 'components/common/Tables/TableCustom'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import {
  TAG_DETAILED_CREATORS,
  TAG_DETAILED_NARRATIVES_COMMUNITIES,
  TAG_DETAILED_POSTS,
} from 'settings/columns.settings'
import { store } from 'store'
import { manageInfoColumn } from 'components/Manage/ManageInfoColumn/ManageInfoColumn'
import { CrossIcon } from 'components/common/CrossIcon/CrossIcon'
import { ReactComponent as ArrowIcon } from 'assets/images/arrow_right.svg'
import { notification } from 'antd'
import './ManageTagDetailsTable.scss'
export const ManageTagsDetailTable = observer(() => {
  const { loaderStore, manageTagsStore } = store
  const {
    narrativesTableData,
    communitiesTableData,
    postsTableData,
    creatorsTableData,
    getSelectedTagID,
    fetchTagDetails,
    actionToAddOrRemoveTag,
  } = manageTagsStore

  useEffect(() => {
    fetchTagDetails(getSelectedTagID)
  }, [])

  const ManageNarrativeColumn = [
    manageInfoColumn({
      onClickView: (col: any) => {},
      width: '50%',
    }),
    ...TAG_DETAILED_NARRATIVES_COMMUNITIES,
    {
      dataIndex: 'col4',
      render: (col: any) => {
        return <CrossIcon onClick={() => HandleCrossClick(col)} />
      },
      width: '10%',
      align: 'left',
    },
  ]
  const ManageCommunityColumn = [
    manageInfoColumn({
      onClickView: (col: any) => {},
      width: '50%',
    }),
    ...TAG_DETAILED_NARRATIVES_COMMUNITIES,
    {
      dataIndex: 'col4',
      render: (col: any) => {
        return <CrossIcon onClick={() => HandleCrossClick(col)} />
      },
      width: '10%',
      align: 'left',
    },
  ]
  const ManagePostColumn = [
    manageInfoColumn({
      onClickView: (col: any) => {},
      width: '40%',
    }),
    ...TAG_DETAILED_POSTS,
    {
      dataIndex: 'col4',
      render: (col: any) => {
        return <CrossIcon onClick={() => HandleCrossClick(col)} />
      },
      width: '10%',
      align: 'left',
    },
  ]
  const ManageCreatorColumn = [
    manageInfoColumn({
      onClickView: (col: any) => {},
      width: '50%',
    }),
    ...TAG_DETAILED_CREATORS,
    {
      dataIndex: 'col4',
      render: (col: any) => {
        return <CrossIcon onClick={() => HandleCrossClick(col)} />
      },
      width: '10%',
      align: 'left',
    },
  ]
  const NotificationContent = ({ data, unduHandler }: { data: any; unduHandler: any }) => {
    return (
      <>
        <section style={{ display: 'flex' }}>
          <section>Narrative no longer using this tag.</section>
          <section
            onClick={() => unduHandler(data)}
            style={{ marginLeft: '20px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            {' '}
            <ArrowIcon /> <section style={{ marginLeft: '10px' }}> Undu</section>
          </section>
        </section>
      </>
    )
  }
  const handleNotificationUnduClick = ({
    id,
    type,
  }: {
    id: string
    type: 'narrative' | 'community' | 'post' | 'creator'
  }) => {
    actionToAddOrRemoveTag({ action: 'apply', tagId: getSelectedTagID as any, id: id as any, type })
  }
  const HandleCrossClick = async (data: any) => {
    await actionToAddOrRemoveTag({
      action: 'remove',
      tagId: getSelectedTagID as any,
      id: data.id as any,
      type: data.type,
    })
    notification.info({
      message: ``,
      duration: 1,
      description: <NotificationContent unduHandler={handleNotificationUnduClick} data={data} />,
      placement: 'bottomRight',
      className: 'black-notifiaction',
      style: { padding: '0px' },
    })
  }
  return (
    <section>
      <Card variant='1'>
        <Spin size='large' spinning={loaderStore.isLoadingTags}>
          <Tabs className='c-tabs'>
            <Tabs.TabPane tab={`Narratives`} key='1'>
              <TableCustom
                style={{ position: 'absolute' }}
                data={narrativesTableData}
                columns={ManageNarrativeColumn}
                pagination={[]}
                onTableChange={[]}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={`communities`} key='2'>
              <TableCustom
                data={communitiesTableData}
                columns={ManageCommunityColumn}
                pagination={[]}
                onTableChange={[]}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Creators`} key='3'>
              <TableCustom data={creatorsTableData} columns={ManageCreatorColumn} pagination={[]} onTableChange={[]} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={`posts`} key='4'>
              <TableCustom data={postsTableData} columns={ManagePostColumn} pagination={[]} onTableChange={[]} />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </Card>
    </section>
  )
})
