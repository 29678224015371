import { notification } from 'antd'

export const openNotification = ({
  type = 'info',
  message,
  description,
}: {
  type: 'info' | 'error'
  message?: string
  description: string
}) => {
  notification[type]({
    message,
    description,
    className: 'c-notification',
    style: {
      width: 450,
    },
  })
}
