import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function updateUserRolePendService({ userId, roleString }: { userId: string; roleString: string }) {
  try {
    const { data } = await axios.put(`${process.env.REACT_APP_API}${ROUTES.user}/${userId}`, {
      role: roleString.toLocaleLowerCase(),
    })

    return { data }
  } catch (error: any) {
    LogService.error({ message: 'updateUserRole', error })
    return { data: null, error: error.response?.data?.message }
  }
}
