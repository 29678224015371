import { ReactComponent as Crossicon } from 'assets/images/cross_black.svg'
import './CrossIcon.scss'

interface Props {
  onClick: () => void
}

export const CrossIcon = ({ onClick }: Props): JSX.Element => {
  return (
    <div className='cursor_pointer'>
      <Crossicon onClick={() => onClick()} />
    </div>
  )
}
