import { Form, Input, Select, Row, Col, FormInstance, Checkbox, Tooltip } from 'antd'
import { IRole } from 'models/models'

import './AddUserForm.scss'

export const AddUserForm = ({ form, roles }: { form: FormInstance<any>; roles: IRole[] }) => {
  const { Option } = Select
  const userRoleID = roles.find((role) => role.role === 'USER')?.id

  return (
    <Form
      form={form}
      className='c-add-user-form'
      name='basic'
      initialValues={{ username: '', email: '', temporaryPassword: '', role: userRoleID, notifyUser: false }}>
      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Username</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='username'
            className='c-add-user-form__item'
            rules={[{ required: true, message: 'Please add an username!' }]}>
            <Input className='c-add-user-form__input' />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Email</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='email'
            className='c-add-user-form__item'
            rules={[{ required: true, message: 'Please add an email!', type: 'email' }]}>
            <Input className='c-add-user-form__input' />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Password</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='temporaryPassword'
            className='c-add-user-form__item'
            rules={[{ required: false, type: 'string' }]}>
            <Tooltip
              placement='top'
              title='Temporary password must contain at least 8 characters including: uppercase, lowercase, special character and
              number. If left blank, a temporary password will be auto generated.'>
              <Input.Password className='c-add-user-form__input' />
            </Tooltip>
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Role</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='role'
            className='c-add-user-form__item'
            rules={[{ required: true, message: 'Please select a role' }]}>
            <Select style={{ width: '100%' }}>
              {roles?.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.role}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Don't Notify</b>
        </Col>
        <Col span={18}>
          <Form.Item name='notifyUser' valuePropName='checked'>
            <Checkbox className='c-add-user-form'> Do not send the user login information </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
