import { communityAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { UtilService } from 'services/Util/Util'
import { ManageStore } from './manage.store'

export class ManageCommunitiesStore extends ManageStore {
  storeType: 'tag' | 'community' | 'narrative' = 'community'

  isRequestModalOpen: boolean = false
  isPostRequestModalOpen: boolean = false

  get filterTableTab1() {
    return `owner_id:eq:${this.userID}`
  }
  get filterTableTab2() {
    const ms = new Date().getTime() - 24 * 60 * 60 * 1000
    const date = new Date(ms).toISOString()
    return `created:gt:${date}`
  }
  get filterTableTab3() {
    return ``
  }

  constructor() {
    super()

    makeObservable(this, {
      isRequestModalOpen: observable,
      isPostRequestModalOpen: observable,

      setIsRequestModalOpen: action.bound,
      setIsPostRequestModalOpen: action.bound,
      submitCommunity: action.bound,
    })
  }

  setIsRequestModalOpen = (state: boolean) => {
    this.isRequestModalOpen = state
  }

  setIsPostRequestModalOpen = (state: boolean) => {
    this.isPostRequestModalOpen = state
  }

  submitCommunity = async (communityDescription: string) => {
    try {
      await communityAPI.submitCommunity(communityDescription)
      this.setIsRequestModalOpen(false)
      this.setIsPostRequestModalOpen(true)
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error during submission of the community.',
        description: 'An error occured while submitting your community, please try again soon.',
      })
    }
  }
}
