//BitChute does not support this feature yet https://webapps.stackexchange.com/questions/139203/link-to-bitchute-video-at-specific-starting-time
//Same for Rumble

export const getSnippetsFull = ({
  snippets,
  platform,
  videoId,
}: {
  snippets: any[]
  platform?: string
  videoId?: string
}) => {
  return snippets
    .map((snippet) => {
      switch (platform) {
        case 'YouTube':
          return `<a target="_blank" rel="noreferrer" href="https://www.youtube.com//watch?v=${videoId}&t=${
            snippet.offsetSeconds
          }" class="c-video-thumbnail__time">Play @ ${Math.floor(snippet.offsetSeconds / 60)}m ${
            snippet.offsetSeconds % 60
          }s </a> ${snippet.snippet}`
        case 'BitChute':
          return `<span target="_blank" rel="noreferrer" class="c-video-thumbnail__description">Play @ ${Math.floor(
            snippet.offsetSeconds / 60,
          )}m ${snippet.offsetSeconds % 60}s </span> ${snippet.snippet}`
        case 'Rumble':
          return `<span target="_blank" rel="noreferrer"  class="c-video-thumbnail__description">Play @ ${Math.floor(
            snippet.offsetSeconds / 60,
          )}m ${snippet.offsetSeconds % 60}s </span> ${snippet.snippet}`
        default:
          return `<span target="_blank" rel="noreferrer"  class="c-video-thumbnail__description">Play @ ${Math.floor(
            snippet.offsetSeconds / 60,
          )}m ${snippet.offsetSeconds % 60}s </span> ${snippet.snippet}`
      }
    })
    .join('<br/> <br/>')
}
