import axios from 'axios'

interface Props {
  tagId: string
  type: 'narrative' | 'community'
  id: string
  action: 'apply' | 'remove'
}

export const addTag = async ({ tagId, type, id, action }: Props) => {
  const data = await axios.post(`/tag/${tagId}/action`, { entity_type: type, entity_id: id, action })
  return data
}
