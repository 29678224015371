import './FollowButton.scss'
interface Props {
  followingStatus: boolean
  tagID: string
  updateTagStatus: (tagID: string, followingStatus: boolean) => void
}

export const FollowButton = ({ followingStatus, updateTagStatus, tagID }: Props) => {
  return (
    <div
      className='c-follow-button'
      onClick={async () => {
        try {
          updateTagStatus(tagID, !followingStatus)
        } catch (e) {}
      }}>
      <span className='c-follow-button__text'>{followingStatus ? '- Unfollow' : '+ Follow'}</span>
    </div>
  )
}
