import millify from 'millify'

import { ReactComponent as WarningIcon } from 'assets/images/warning-triangle.svg'
import { ReactComponent as IconInfo } from 'assets/images/icon-info.svg'

import './DefineNarrativeMatchingResults.scss'

interface Props {
  matchingResults: number
  type: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany'
}

export const DefineNarrativeMatchingResults = ({ matchingResults, type }: Props) => {
  return (
    <div className='c-define-narrative-matching-results'>
      <p className='c-define-narrative-matching-results__title'>
        {type === 'noSearch' ? (
          <b>No results</b>
        ) : (
          <>
            <b>{millify(matchingResults)} matching results</b> from the last month
          </>
        )}
      </p>

      {type === 'tooMany' && (
        <div className='c-define-narrative-matching-results__warning'>
          <WarningIcon className='c-define-narrative-matching-results__warning-icon' />
          <div className='c-define-narrative-matching-results__warning-text'>
            Your search results in too many items to be useful. Consider refining your keywords.
          </div>
        </div>
      )}

      {type === 'noResultsBack' && (
        <div className='c-define-narrative-matching-results__no-results-back'>
          <IconInfo className='c-define-narrative-matching-results__no-results-back__icon' />
          <div className='c-define-narrative-matching-results__no-results-back__text'>
            This can happen because:
            <ul>
              <li>the keywords are too restrictive</li>
              <li>the data is older than the last month</li>
              <li>what you’re defining happens infrequently, but you can continue creating this narrative.</li>
            </ul>
          </div>
        </div>
      )}

      {type === 'noSearch' && (
        <div className='c-define-narrative-matching-results__no-results'>
          <IconInfo className='c-define-narrative-matching-results__no-results__icon' />
          <div className='c-define-narrative-matching-results__no-results__text'>
            Complete “Boolean keword search” to have results.
          </div>
        </div>
      )}
    </div>
  )
}
