import { UtilService } from './Util'
import { ReactComponent as IconPlayVideo } from 'assets/images/play-video.svg'
import RumbleLogo from 'assets/images/rumble-full-logo.svg'
import PodcastsLogo from 'assets/images/microphone-2.svg'

interface Props {
  videoId: string
  platform: 'YouTube' | 'Rumble' | 'BitChute' | 'Podcast'
  mediaUrl?: string | null
}

export const getVideoFrame = ({ videoId, platform, mediaUrl }: Props) => {
  const videoSrc = UtilService.getVideoURL({ platform: platform, videoId })
  switch (platform) {
    case 'YouTube':
      return (
        <iframe
          className='c-video-frame__youtube'
          src={`https://www.youtube.com/embed/${videoId}`}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      )
    case 'BitChute':
      return (
        <iframe
          className='c-video-frame__bitchute'
          src={videoSrc}
          title='Bitchute video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      )
    case 'Rumble':
      return (
        <>
          <div
            className='c-video-frame__rumble'
            onClick={() => {
              window.open(
                platform === 'Rumble'
                  ? `https://rumble.com/${videoId}`
                  : platform === 'Podcast'
                  ? mediaUrl !== null
                    ? mediaUrl
                    : ''
                  : '',
                '_blank',
              )
            }}>
            <img className='c-video-frame__rumble-logo' src={RumbleLogo} alt='Rumble link'></img>
            <IconPlayVideo className='c-video-frame__rumble-play' />
          </div>
        </>
      )
    case 'Podcast':
      return (
        <div
          className='c-video-frame__podcast'
          onClick={() => {
            window.open(`_blank`)
          }}>
          <img className='c-video-frame__podcast-logo' src={PodcastsLogo} alt='Podcast link'></img>
        </div>
      )
  }
}
