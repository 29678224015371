import { Dropdown, Input, Menu } from 'antd'
import { ILightTag } from 'models/models'
import { useState } from 'react'
import './AddTags.scss'

interface Props {
  allTags?: ILightTag[]
  usedTags: string[]
  createTag?: (tagLabel: string, id: string) => void
  addTag?: (tagLabel: string, id: string) => void
  contentId?: string
  actionTag?: ({ action, tagId, id }: { action: 'apply' | 'remove'; tagId: string; id: string }) => void
}

export const AddTags = ({ allTags, usedTags, createTag, contentId, actionTag }: Props) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const [tagsToShow, setTagsToShow] = useState(allTags?.slice(0, 4))
  const [inputValue, setInputValue] = useState('')

  const searchTags = (keyword: string) => {
    setTagsToShow(allTags?.filter((tag) => tag.label.includes(keyword)))
  }

  const tagsList = (
    <Menu>
      <Menu.Item key='0'>
        <Input
          onChange={(e) => {
            setInputValue(e.target.value)
            searchTags(e.target.value)
          }}
          placeholder={'Search for tags'}
        />
      </Menu.Item>

      {tagsToShow?.map((tag) => {
        let elementsArray = []
        let tagClone = { ...tag }

        while (inputValue && tagClone.label.includes(inputValue)) {
          elementsArray.push(tagClone.label.slice(0, tagClone.label.indexOf(inputValue)))
          elementsArray.push(
            tagClone.label.slice(
              tagClone.label.indexOf(inputValue),
              tagClone.label.indexOf(inputValue) + inputValue.length,
            ),
          )
          tagClone.label = tagClone.label.slice(
            tagClone.label.indexOf(inputValue) + inputValue.length,
            tagClone.label.length,
          )
        }

        elementsArray.push(tagClone.label)

        return (
          <Menu.Item
            key={tag.id}
            onClick={() => {
              if (contentId && actionTag) actionTag({ action: 'apply', tagId: tag.id, id: contentId })
            }}>
            <div className='c-add-tag__tag'>
              <span>
                {elementsArray.map((el, index) => {
                  return index % 2 ? <span className='c-add-tag__tag--bold'>{el}</span> : el
                })}
              </span>
              {usedTags?.includes(tag.label) && <span className='c-add-tag__tag--used'>Already used</span>}
            </div>
          </Menu.Item>
        )
      })}

      {createTag && (
        <Menu.Item key='1'>
          <div className='c-add-tag__button'>
            <p
              className='c-add-tag__button-text'
              onClick={() => {
                if (contentId) createTag(inputValue, contentId)
              }}>
              + Create tag
            </p>
          </div>
        </Menu.Item>
      )}
    </Menu>
  )
  return (
    <Dropdown
      className='c-add-tag__dropdown'
      overlayClassName='c-add-tag__dropdown__overlay'
      overlay={tagsList}
      trigger={['hover']}
      visible={menuVisible}
      onVisibleChange={(state) => {
        setMenuVisible(state)
      }}>
      <div className='c-add-tag'>
        <p className='c-add-tag__text'>+ Add Tags</p>
      </div>
    </Dropdown>
  )
}
