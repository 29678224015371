import axios from 'axios'

import { ROUTES } from 'settings/settings'

interface Props {
  name: string
  type: string
  description: string
  communities: string[]
  keywords_expression: string
  parent_narrative_id: string
  related_contents?: string[]
  tenant_id: string
}

export const publishNarrative = async (narrative: Props) => {
  let url = process.env.REACT_APP_API + ROUTES.newNarrativePublish

  const { data } = await axios.post(url, narrative)

  return data
}
