import { Row, Col } from 'antd'
import { useEffectOnce, useUnmount } from 'react-use'

import { store } from 'store'

import { Users } from 'components/common/Users/Users/Users'
import { NavigationSettings } from 'components/common/Navigation/NavigationSettings/NavigationSettings'
import { ListPage } from 'components/common/ListPage/ListPage/ListPage'

export function UsersPage(): JSX.Element {
  const { userStore } = store

  useEffectOnce(() => {
    userStore.getUsers()
    userStore.getRoles()
  })

  useUnmount(() => {
    userStore.setUsers([])
  })

  return (
    <ListPage>
      <Row>
        <Col span={6}>
          <NavigationSettings />
        </Col>

        <Col span={18}>
          <Users />
        </Col>
      </Row>
    </ListPage>
  )
}
