import { ListPage } from 'components/common/ListPage/ListPage/ListPage'
import { ListPageSection } from 'components/common/ListPage/ListPageSection/ListPageSection'
import { MonitorSnippetList } from 'components/Monitor/MonitorSnippetList/MonitorSnippetList'
import { MonitorSurpriseModal } from 'components/Monitor/MonitorSurpriseModal/MonitorSurpriseModal'
import { MonitorTagsList } from 'components/Monitor/MonitorTags/MonitorTagsList'

import { store } from 'store'

import { observer } from 'mobx-react-lite'
import { useUnmount } from 'react-use'

export const MonitorTagsPage = observer(() => {
  const { monitorTags } = store
  const { surpriseModalData /*, sourceModalData*/, showSurprise, resetStore } = monitorTags

  useUnmount(() => {
    resetStore()
  })

  return (
    <ListPage>
      <ListPageSection variant='2' left={<MonitorTagsList />} right={<MonitorSnippetList />} />

      <MonitorSurpriseModal
        isVisible={!!surpriseModalData}
        close={() => {
          showSurprise(false)
        }}
        data={surpriseModalData}
      />
    </ListPage>
  )
})
