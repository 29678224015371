import './KeywordsBox.scss'

interface Props {
  text: string
}

export const KeywordsBox = ({ text }: Props) => {
  return (
    <div className='c-keywords-box'>
      {text.split(' ').map((el) => {
        const str = el + ' '
        if (el === 'OR' || el === 'AND' || el === 'NOT') {
          return <b>{str}</b>
        }
        return str
      })}
    </div>
  )
}
