import { ISnippet } from 'models/models'
import { ReactComponent as SafetyIcon } from 'assets/images/safety-icon.svg'
import { ReactComponent as DropdownVerticalDots } from 'assets/images/dots-vertical.svg'
import { ReactComponent as HashtagIcon } from 'assets/images/icon-hashtag.svg'
import { ReactComponent as PlayIcon } from 'assets/images/icon-play.svg'
import { ReactComponent as OpenIcon } from 'assets/images/icon-open.svg'
import SurpriseSnippet from 'assets/images/surprise-snippet.png'

import { useEffect, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import millify from 'millify'

import './MonitorSnippet.scss'

interface Props {
  snippet: ISnippet
  communities: { id: string | number; name: string }[]
  showSurprise: (val: boolean) => void
}

export const MonitorSnippet = ({ snippet, communities, showSurprise }: Props) => {
  const monthLookup = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const [openedSnippet, setOppenedSnippet] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const isSnippetTooLong = snippet.text.length > 260

  const date = new Date(snippet.date)
  const day = date.getDay()
  const month = monthLookup[date.getMonth()]
  const year = date.getFullYear()

  const formatedDate = `${month} ${day}, ${year}`

  const [communitiesComp, setCommunitiesComp] = useState<{ id: string | number; name: string }[]>([])

  useEffect(() => {
    if (communities.length > 0 && dropdownVisible) {
      if (snippet.author.communities)
        setCommunitiesComp(
          snippet.author.communities.map((community) => {
            const res = communities.filter((el) => el.id === community)[0]
            if (res) return res
            else return { id: -1, name: 'Hidden' }
          }),
        )
    }
  }, [communities, dropdownVisible])

  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <div className='c-snippet__dropdown-item'>
          <HashtagIcon />
          <span className='c-snippet__dropdown-text'>Add hashtag</span>
        </div>
      </Menu.Item>
      <Menu.Item key='1'>
        <div className='c-snippet__dropdown-item'>
          <PlayIcon />
          <span className='c-snippet__dropdown-text'>Play source</span>
        </div>
      </Menu.Item>
      <Menu.Item key='2'>
        <div className='c-snippet__dropdown-item'>
          <OpenIcon />
          <span className='c-snippet__dropdown-text'>Open source</span>
        </div>
      </Menu.Item>
    </Menu>
  )

  const creatorMenu = (
    <div className='c-snippet__creator-dropdown'>
      <div className='c-snippet__creator-dropdown__header'>
        <img className='c-snippet__creator-dropdown__header-img' src={snippet.author.channel_thumb_url} alt='' />
        <div className='c-snippet__creator-dropdown__header-info'>
          <div className='c-snippet__creator-dropdown__header-info--name'> {snippet.author.channel_title}</div>
          <div className='c-snippet__creator-dropdown__header-info--data'>
            {millify(snippet.author.view_count | 0)} views | {millify(snippet.author.followers_count | 0)} subscribers
          </div>
        </div>
      </div>
      <div className='c-snippet__creator-dropdown__communities'>
        <div className='c-snippet__creator-dropdown__communities-title'>COMMUNITIES</div>
        <div className='c-snippet__creator-dropdown__communities-list'>
          {communities.length > 0
            ? communitiesComp.map((community) => {
                return <div key={community.id}>{community.name}</div>
              })
            : snippet.author.communities?.map((el) => {
                return <div key={el}>{el}</div>
              })}
        </div>
      </div>
    </div>
  )

  if (snippet.type === 'surprise') {
    return (
      <img
        src={SurpriseSnippet}
        onClick={() => {
          showSurprise(true)
        }}
        className='c-snippets-list__surprise'
        alt={'A surprise just for you'}
      />
    )
  }

  return (
    <div className='c-snippet'>
      <div className='c-snippet__text'>
        {isSnippetTooLong ? (openedSnippet ? snippet.text : snippet.text.slice(0, 260)) : snippet.text}
        {isSnippetTooLong && (
          <>
            ...{' '}
            {openedSnippet ? (
              <span onClick={() => setOppenedSnippet(false)} className='c-snippet__button'>
                hide
              </span>
            ) : (
              <span onClick={() => setOppenedSnippet(true)} className='c-snippet__button'>
                more
              </span>
            )}
          </>
        )}
      </div>

      <div className='c-snippet__details'>
        <div className='c-snippet__details-left'>
          {snippet.viewsMilify} views <div className='c-snippet__dot' /> {formatedDate}
          <div className='c-snippet__dot' />
          {snippet.author.channel_title ? (
            <Dropdown
              onVisibleChange={(visible) => {
                setDropdownVisible(visible)
              }}
              trigger={['click']}
              placement='topCenter'
              overlay={creatorMenu}>
              <span>
                {snippet.author.channel_thumb_url && (
                  <img className='c-snippet__author-img' src={snippet.author.channel_thumb_url} alt='' />
                )}
                {snippet.author.channel_title}
              </span>
            </Dropdown>
          ) : (
            snippet.channelId
          )}
          <div className='c-snippet__dot' /> <SafetyIcon className='c-snippet__author-img' /> {snippet.riskScore} %
        </div>
        <div className='c-snippet__details-right'>
          <Dropdown trigger={['click']} overlay={menu}>
            <DropdownVerticalDots className='c-snippet__details__dropdown' />
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
