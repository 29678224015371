import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function getMatchingResultsNew({ keywords, description }: { keywords: string[]; description: string }) {
  try {
    const response = await axios({
      method: 'POST',
      url: `/${ROUTES.newNarrativeMatchingResults}`,
      baseURL: process.env.REACT_APP_API,
      data: { keywords, description },
    })

    const data: number = response?.data?.count

    return { data }
  } catch (error) {
    LogService.error({ message: 'getMatchingResults error', error })

    return { data: 0, error }
  }
}
