import { getAuthToken } from './getAuthToken'
import { generateURLSearchParams } from './generateURLSearchParams'
import { getNarrativeTableData } from './getNarrativeTableData'
import { getTableData, getTagDetailsData } from './getTableData'
import { getRandomColor } from './getRandomColor'
import { getSourcesDataPretty } from './getSourcesDataPretty'
import { getRandomInt } from './getRandomInt'
import { getKeyByValue } from './getKeyByValue'
import { truncate } from './truncate'
import { getSourceFromChannelId } from './getSourceFromChannelId'
import { sumBy } from './sumBy'
import { openNotification } from './openNotification'
import { varToString } from './varToString'
import { getSnippetsFull } from './getSnippetsFull'
import { getVideoURL } from './getVideoURL'
import { getVideoFrame } from './getVideoFrame'
import { getPlatformColor } from './getPlatformColor'

export const UtilService = {
  getAuthToken,
  generateURLSearchParams,
  getNarrativeTableData,
  getTableData,
  getRandomColor,
  getSourcesDataPretty,
  getRandomInt,
  getKeyByValue,
  truncate,
  getSourceFromChannelId,
  sumBy,
  openNotification,
  varToString,
  getSnippetsFull,
  getVideoURL,
  getVideoFrame,
  getPlatformColor,
  getTagDetailsData,
}
