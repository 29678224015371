import { Link } from 'react-router-dom'

import './SignInLink.scss'

export const SignInLink = () => {
  return (
    <p className='c-sign-in-link'>
      <span className='c-sign-in-link__text'>Changed your mind? </span>
      <Link to='/signin'>Sign in</Link>
    </p>
  )
}
