import { LinkPreview } from '@dhaiwat10/react-link-preview'
import { Input, Modal, Spin } from 'antd'
import { KeywordsBox } from 'components/common/KeywordsBox/KeywordsBox'
import { TableTags } from 'components/common/TableTags/TableTags'
import { ICommunity, INarrative } from 'models/models'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as Statistics } from 'assets/images/statistics-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icon-close.svg'
import { EditDropdown } from 'components/common/EditDropdown/EditDropdown'

import './ManageViewModal.scss'

interface Props {
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
  type: 'community' | 'narrative'
  data: ICommunity | INarrative | null
  isLoading?: boolean
  openModal: (modal: string) => void
}

export const ManageViewModal = ({ data, isVisible, setIsModalVisible, type, isLoading = false, openModal }: Props) => {
  let clickedSomething = false

  const delay = async (time: number) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        return resolve('')
      }, time)
    })
  }

  let narrativeData = data as INarrative

  const topButtons = (
    <div className='c-manage__modal__header-buttons'>
      {data?.id && (
        <EditDropdown
          className='c-manage__modal__header-buttons-item'
          name={type}
          id={data?.id}
          variant='1'
          openModal={() => {}}
        />
      )}

      <Statistics
        className='c-manage__modal__header-buttons-item'
        onClick={async () => {
          clickedSomething = true
          console.log('openStatistics')
          await delay(500)
          clickedSomething = false
        }}
      />

      <CloseIcon
        className='c-manage__modal__header-buttons-item'
        onClick={() => {
          setIsModalVisible(false)
        }}
      />
    </div>
  )

  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      title={
        <div className='c-manage__modal__title__wraper'>
          <div className='c-manage__modal__title'>
            <p>{data?.name}</p>
            {narrativeData?.isMLGenerated && <div className='c-manage__modal__is-MLG'>ML</div>}
          </div>
        </div>
      }
      wrapClassName='c-manage__modal'
      visible={isVisible}
      closable
      centered
      onOk={() => setIsModalVisible(false)}
      onCancel={() => {
        if (!clickedSomething) setIsModalVisible(false)
      }}
      closeIcon={topButtons}>
      <Spin spinning={isLoading}>
        <div className='c-manage__modal__generic-info'>
          <div className='c-manage__modal__generic-info__box'>
            <p className='c-manage__modal__subtitle'>Creator</p>
            <p className='c-manage__modal__generic-info__info'>{data?.creatorName}</p>
          </div>
          <div className='c-manage__modal__generic-info__box'>
            <p className='c-manage__modal__subtitle'>Date created</p>
            <p className='c-manage__modal__generic-info__info'>{data?.dateCreated.replace(/\./g, '/')}</p>
          </div>
        </div>
        <div className='c-manage__modal__category'>
          <p className='c-manage__modal__subtitle'>Description</p>
          <p>{data?.description}</p>
        </div>
        {data?.tags && (
          <div className='c-manage__modal__category'>
            <p className='c-manage__modal__subtitle'>Tags</p>
            <Input className='c-manage__modal__subtitle__search' placeholder={'Search for tags'} />
            <TableTags tagsLimit={6} canAdd={false} tags={data?.tags} />
          </div>
        )}
        <div className='c-manage__modal__category'>
          <p className='c-manage__modal__subtitle'>
            {type === 'narrative' ? 'Saved links' : 'Representative creators'}
          </p>
          {data?.links &&
            data?.links.map((el) => {
              return (
                <LinkPreview
                  showLoader={false}
                  className='c-manage__modal__subtitle__links'
                  url={el}
                  height='auto'
                  key={uuidv4()}
                />
              )
            })}
        </div>
        {narrativeData?.keywords && (
          <div className='c-manage__modal__category'>
            <p className='c-manage__modal__subtitle'>Keyword search</p>
            <KeywordsBox text={narrativeData?.keywords} />
          </div>
        )}
        {narrativeData?.parentNarrativeKeywords && (
          <div className='c-manage__modal__category'>
            <p className='c-manage__modal__subtitle'>Parent narrative</p>
            <KeywordsBox text={narrativeData?.parentNarrativeKeywords} />
          </div>
        )}
        {narrativeData?.communities && (
          <div className='c-manage__modal__category'>
            <p className='c-manage__modal__subtitle'>Communities</p>
            <KeywordsBox text={narrativeData?.communities} />
          </div>
        )}
        <div className='c-manage__modal__category'>
          <p className='c-manage__modal__subtitle'>Shared with</p>
          {narrativeData?.sharedWith?.map((user, index) => {
            return (
              <span key={uuidv4()}>
                {user}
                {index !== (narrativeData.sharedWith?.length || 1) - 1 && ', '}
              </span>
            )
          })}
        </div>
      </Spin>
    </Modal>
  )
}
