import { notification } from 'antd'
import { API, tagAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { ICommunity, ILightTag, INarrative, IPagination } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { PAGINATION_SINGLE_PAGE } from 'settings/settings'
import { ReactComponent as CheckIcon } from 'assets/images/check_notification.svg'
export class ManageStore {
  userID: string | null = null
  storeType: 'tag' | 'community' | 'narrative' = 'tag' //this variable should be set in every store that extends this one
  isViewModalOpen: boolean = false
  isShareModalOpen: boolean = false
  isEditModalOpen: boolean = false
  isDeleteModalOpen: boolean = false
  paginationTableTab1: IPagination = PAGINATION_SINGLE_PAGE
  paginationTableTab2: IPagination = PAGINATION_SINGLE_PAGE
  paginationTableTab3: IPagination = PAGINATION_SINGLE_PAGE

  dataTableTab1: any[] = []
  dataTableTab2: any[] = []
  dataTableTab3: any[] = []

  viewModalData: INarrative | ICommunity | null = null

  lightTags: ILightTag[] = []

  openedModalItemID: string | null = null

  constructor() {
    makeObservable(this, {
      isViewModalOpen: observable,
      isShareModalOpen: observable,

      paginationTableTab1: observable,
      paginationTableTab2: observable,
      paginationTableTab3: observable,

      dataTableTab1: observable,
      dataTableTab2: observable,
      dataTableTab3: observable,
      isEditModalOpen: observable,
      isDeleteModalOpen: observable,
      setIsViewModalOpen: action.bound,
      setIsShareModalOpen: action.bound,
      updatePagination: action.bound,
      updatePaginationTotal: action.bound,

      fetchData: action.bound,

      saveTableData: action.bound,
      setIsEditModalOpen: action.bound,
      setIsDeleteModalOpen: action.bound,
    })
  }

  get filterTableTab1() {
    return ''
  }
  get filterTableTab2() {
    return ''
  }
  get filterTableTab3() {
    return ''
  }

  get tableTabData1() {
    return UtilService.getTableData({ source: this.dataTableTab1, table: this.storeType })
  }
  get tableTabData2() {
    return UtilService.getTableData({ source: this.dataTableTab2, table: this.storeType })
  }
  get tableTabData3() {
    return UtilService.getTableData({ source: this.dataTableTab3, table: this.storeType })
  }

  fetchData = async ({ tab }: { tab: 1 | 2 | 3 }) => {
    try {
      const { data, total } = await API.get({
        route: this.storeType,
        page: this[`paginationTableTab${tab}`].current,
        pageSize: this[`paginationTableTab${tab}`].pageSize,
        filter: this[`filterTableTab${tab}`],
        getError: true,
      })

      this.saveTableData({ tab, data: this.formatData(data.items) })
      this.updatePaginationTotal({ tab, total })
    } catch (e: any) {
      UtilService.openNotification({ type: 'error', message: 'Error during fetching the data', description: e.message })
    }
  }

  fetchItem = async (id: number) => {
    try {
      const { data } = await API.get({
        route: this.storeType,
        id,
        getError: true,
      })

      this.saveItemData(data)
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error during fetching single item data',
        description: e.message,
      })
    }
  }

  saveTableData = ({ tab, data }: { tab: 1 | 2 | 3; data: any }) => {
    this[`dataTableTab${tab}`] = data
  }

  saveItemData = (data: any) => {
    if (!data) return

    const date = new Date(data.created)
    switch (this.storeType) {
      case 'community':
        return (this.viewModalData = {
          id: data.id,
          creatorImage: '/images/android-icon-96x96.png',
          creatorName: 'Pedulum',
          dateCreated: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
          name: data.name,
          description: data.description,
          tags: data.tags,
          links: data.sample_creators,
        })
      case 'narrative':
        return (this.viewModalData = {
          id: data.id,
          creatorImage: '/images/android-icon-96x96.png',
          creatorName: 'Pedulum',
          dateCreated: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
          name: data.name,
          description: data.description,
          tags: data.tags,
          links: data.related_links,
          keywords: data.boolean_query,
        })
    }
  }

  setIsViewModalOpen = (state: boolean) => {
    if (state === false) this.viewModalData = null
    this.isViewModalOpen = state
  }

  setIsShareModalOpen = (state: boolean) => {
    this.isShareModalOpen = state
  }
  setIsEditModalOpen = (state: boolean) => {
    this.isEditModalOpen = state
  }
  setIsDeleteModalOpen = (state: boolean) => {
    this.isDeleteModalOpen = state
  }
  updatePagination = ({ tab, pagination }: { tab: 1 | 2 | 3; pagination: any }) => {
    return (this[`paginationTableTab${tab}`] = pagination)
  }

  updatePaginationTotal = ({ tab, total }: { tab: 1 | 2 | 3; total: number }) => {
    return (this[`paginationTableTab${tab}`].total = total)
  }

  openView = (id: number) => {
    this.setIsViewModalOpen(true)
    this.fetchItem(id)
  }

  formatData = (data: any[]) => {
    switch (this.storeType) {
      case 'community':
        return data.map((community) => {
          //TODO: add the img and creator name : update , the name should be loaded from the users endpoint, and the image will be the default one
          const date = new Date(community.created)
          return {
            id: community.id,
            dateCreated: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
            name: community.name,
            description: community.description,
            tags: community.tags,
            creatorImage: '/images/android-icon-96x96.png',
            creatorName: 'Pendulum',
          }
        })
      case 'narrative':
        return data.map((narrative) => {
          //TODO: add the img and creator name : update , the name should be loaded from the users endpoint, and the image will be the default one
          const date = new Date(narrative.created)
          return {
            id: narrative.id,
            dateCreated: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
            name: narrative.name,
            description: narrative.description,
            tags: narrative.tags,
            creatorImage: '/images/android-icon-96x96.png',
            creatorName: 'Pendulum',
          }
        })
      case 'tag':
        return data.map((tag) => {
          return {
            id: tag.id,
            name: tag.label,
            narrativesCount: tag.narrative_count,
            communitiesCount: tag.community_count,
            creatorsCount: tag.creator_count,
            postsCount: tag.content_count,
            following: tag.is_followed,
          }
        })
      default:
        return []
    }
  }

  openModal = (modal: string, id?: string | null) => {
    switch (modal) {
      case 'share':
        return this.setIsShareModalOpen(true)
      case 'edit':
        return this.setIsEditModalOpen(true)
      case 'delete':
        return this.setIsDeleteModalOpen(true)
    }
    if (id) this.setOpenedItemID(id)
  }

  setOpenedItemID = (id: string | null) => {
    this.openedModalItemID = id
  }

  setUserID = (id: string | null) => {
    this.userID = id
  }

  setLightTags = (lightTags: ILightTag[]) => {
    this.lightTags = lightTags
  }

  getLightTags = async () => {
    try {
      const data = await tagAPI.getSummaryTags()
      this.setLightTags(data.tags)
    } catch (e) {}
  }

  createTag = async (tagLabel: string, id: string) => {
    try {
      const { data } = await tagAPI.createTag(tagLabel)
      this.lightTags.push({ label: data.label, id: data.id })

      this.actionTag({ action: 'apply', tagId: data.id, id })
    } catch (e) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error occured while creating the tag.',
        description: 'An error occured wile tryed to create your tag.',
      })
    }
  }

  actionTag = async ({ action, tagId, id }: { action: 'apply' | 'remove'; tagId: string; id: string }) => {
    try {
      if (this.storeType === 'tag') return
      await tagAPI.addTag({ tagId, action, type: this.storeType, id })

      const tagLabel = this.lightTags.filter((el: any) => el.id === tagId)[0].label

      this.saveTableData({
        tab: 1,
        data: this.dataTableTab1.map((el: any) => {
          const item = el
          if (item.id !== id) return item
          if (action === 'apply') {
            item.tags.push(tagLabel)
          }
          if (action === 'remove') {
            item.tags = item.tags.filter((tag: string) => tag !== tagLabel)
          }
          return item
        }),
      })

      this.saveTableData({
        tab: 2,
        data: this.dataTableTab2.map((el: any) => {
          const item = el
          if (item.id !== id) return item
          if (action === 'apply') {
            item.tags.push(tagLabel)
          }
          if (action === 'remove') {
            item.tags = item.tags.filter((tag: string) => tag !== tagLabel)
          }
          return item
        }),
      })

      this.saveTableData({
        tab: 3,
        data: this.dataTableTab3.map((el: any) => {
          const item = el
          if (item.id !== id) return item
          if (action === 'apply') {
            item.tags.push(tagLabel)
          }
          if (action === 'remove') {
            item.tags = item.tags.filter((tag: string) => tag !== tagLabel)
          }
          return item
        }),
      })
    } catch (e) {
      UtilService.openNotification({
        type: 'error',
        message: `Error while ${action} the tab.`,
        description: `An error occured while ${action} the tag to the ${this.storeType}.`,
      })
    }
  }

  shareItem = async (userIds: string[], action: 'apply' = 'apply') => {
    try {
      if (!this.openedModalItemID) return
      await API.genericShare(this.openedModalItemID, userIds, this.storeType, action, 'viewer')
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error occured while sharing the item.',
        description: e.message,
      })
    }
  }
  editItem = async (
    type: 'narrative' | 'community' | 'tag',
    id: string | null | undefined,
    label: string,
    afterAsyncOperation: (label: string) => void,
    handleCloseModel: (state: boolean) => void,
  ) => {
    try {
      await API.genericEdit(id, type, label)
      afterAsyncOperation(label)
      handleCloseModel(false)
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error occured while Editing the item.',
        description: e.message,
      })
    }
  }

  deleteItem = async (
    type: 'narrative' | 'community' | 'tag',
    id: string | null | undefined,
    handleCloseModel: (state: boolean) => void,
    afterCloseOperation: () => void,
  ) => {
    try {
      // await API.genericDelete(id,type)
      handleCloseModel(false)
      afterCloseOperation()
      notification.open({
        message: '',
        description: <NotificationContent data={{}} />,
        className: 'c-notification',
        duration: 0,
        style: {
          width: '326px',
          height: '85px',
          padding: '15px',
        },
        icon: <CheckIcon style={{ marginLeft: '16px', marginTop: '2px' }} />,
      })
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error occured while Deleting the item.',
        description: e.message,
      })
    }
  }
}
const NotificationContent = ({ data }: { data: any }) => {
  return (
    <>
      <section style={{ fontWeight: 600, fontSize: '16px', display: 'flex', flexDirection: 'column' }}>
        <section>Tag deleted successfully</section>
        <section
          // onClick={() => unduHandler(data)}
          style={{
            marginLeft: '9px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '14px',
            color: '#4595FF',
          }}>
          {' '}
          Undu
        </section>
      </section>
    </>
  )
}
