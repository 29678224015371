import { Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import { useLocation, Link } from 'react-router-dom'

import { store } from 'store'

import './NavigationSettings.scss'
import { ReactComponent as IconYourProfile } from 'assets/images/your-profile.svg'
import { ReactComponent as IconUsers } from 'assets/images/users.svg'

export const NavigationSettings = observer((): JSX.Element => {
  const location = useLocation()
  const { userStore } = store
  const { currentRole } = userStore

  let selectedKeys: string[] = ['1']

  if (location.pathname.includes('/profile')) selectedKeys = ['1']
  else if (location.pathname.includes('/users')) selectedKeys = ['2']

  return (
    <nav className='c-navigation-settings'>
      <Menu mode='vertical' selectedKeys={selectedKeys} triggerSubMenuAction='click'>
        <Menu.Item key='1'>
          <Link to='/settings/profile'>
            <IconYourProfile />
            Profile
          </Link>
        </Menu.Item>

        {currentRole === ('ADMIN' || 'PENDULUM_ADMIN') && (
          <Menu.Item key='2'>
            <Link to='/settings/users'>
              <IconUsers />
              Users
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </nav>
  )
})
