import { makeAutoObservable, runInAction } from 'mobx'

import { ROUTES } from 'settings/settings'
import { route } from 'models/models'

export class LoaderStore {
  isLoadingCommunities = false
  isLoadingCreators = false
  isLoadingVideos = false
  isLoadingUsers = false
  isLabelingVideo = false
  isSendingDefineNarrrativeData = false
  isLoadingNarratives = false
  isLoadingTags = false
  isLoadinFeed = false
  isLoadingStats = false

  constructor() {
    makeAutoObservable(this)
  }

  set(param: any, isLoading: boolean) {
    if (!isLoading) {
      // give it a moment to make sure the data is added to the store
      setTimeout(() => {
        runInAction(() => {
          // @ts-ignore
          this[param] = isLoading
        })
      }, 500)
    } else {
      runInAction(() => {
        // @ts-ignore
        this[param] = isLoading
      })
    }
  }

  setIsLoading = ({ route, isLoading }: { route: route; isLoading: boolean }) => {
    if (route.includes('listUsers') || route.includes('users')) this.set('isLoadingUsers', isLoading)

    if (route.includes('dropdown')) this.set('isLoadingDropdownData', isLoading)

    if (route.includes('assets/onWatchlist')) this.set('isLoadingWatchlistAsset', isLoading)
    if (route.includes('narratives/onWatchlist')) this.set('isLoadingWatchlistCommunity', isLoading)
    if (route.includes('communities/onWatchlist')) this.set('isLoadingWatchlistNarrative', isLoading)

    if (route.includes('labelHistory')) this.set('isLoadingLabeledNarratives', isLoading)

    if (route.includes('labelVideo')) this.set('isLabelingVideo', isLoading)

    if (route.includes(ROUTES.newNarrativeSave) || route.includes(ROUTES.newNarrativePublish))
      this.set('isSendingDefineNarrrativeData', isLoading)

    if (route.includes('community')) this.set('isLoadingCommunities', isLoading)
    if (route.includes('narrative')) this.set('isLoadingNarratives', isLoading)
    if (route.includes('tag')) this.set('isLoadingTags', isLoading)

    if (route.includes('feeds')) this.set('isLoadinFeed', isLoading)
    if (route.includes('channel')) this.set('isLoadingCreators', isLoading)

    if (route.includes('stats')) this.set('isLoadingStats', isLoading)
  }
}
