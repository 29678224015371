import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import './SecondaryNavigation.scss'

export const SecondaryNavigation = () => {
  const location = useLocation()

  let selectedKeys: string[] = ['0']

  if (location.pathname.includes('/tags')) selectedKeys = ['1']
  else if (location.pathname.includes('/narratives')) selectedKeys = ['2']
  else if (location.pathname.includes('/communities')) selectedKeys = ['3']

  return (
    <nav className='c-secondary-navigation'>
      <div className='c-secondary-navigation__container'>
        <Menu
          mode='horizontal'
          selectedKeys={selectedKeys}
          triggerSubMenuAction='click'
          className='c-secondary-navigation__buttons'>
          <Menu.Item key='1'>
            <Link to='tags'>Tags</Link>
          </Menu.Item>

          <Menu.Item key='2'>
            <Link to='narratives'>Narratives</Link>
          </Menu.Item>

          <Menu.Item key='3'>
            <Link to='communities'>Communities</Link>
          </Menu.Item>
        </Menu>
      </div>
    </nav>
  )
}
