import { API } from 'api/api'
import { makeAutoObservable } from 'mobx'
import { IUsers } from 'models/models'
import { UtilService } from 'services/Util/Util'

export class UsersStore {
  users: IUsers[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setUsers = (data: IUsers[]) => {
    this.users = data
  }

  fetchUsers = async () => {
    try {
      const { data } = await API.get({ route: 'user', getError: true })
      this.setUsers(data)
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error during fetching the users',
        description: 'An error occured while fetching the users',
      })
    }
  }
}
