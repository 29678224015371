import { makeAutoObservable } from 'mobx'

export class ResetPasswordStore {
  codeWasSent: boolean = false
  info: { message: string; name?: string; code?: string; type: 'success' | 'error' } | null = null
  userEmailStore: string = ''
  loading: boolean = false
  page: 'start' | 'new_password' | 'succes' = 'start'
  timeLeft: number = 5
  countdown: NodeJS.Timer | undefined

  constructor() {
    makeAutoObservable(this)
  }

  startContDown = () => {
    this.countdown = setInterval(() => {
      if (this.timeLeft > 0) this.timeLeft--
      else if (this.countdown) clearInterval(this.countdown)
    }, 1000)
  }

  setCodeWasSent = (status: boolean) => {
    this.codeWasSent = status
  }
  setInfo = (info: { message: string; name?: string; code?: string; type: 'success' | 'error' } | null) => {
    this.info = info
  }

  setUserEmail = (userEmail: string) => {
    this.userEmailStore = userEmail
  }
  setLoading = (status: boolean) => {
    this.loading = status
  }
  changePage = (newPage: 'start' | 'new_password' | 'succes') => {
    this.page = newPage
  }
  setTimeLeft = (time: number) => {
    this.timeLeft = time
  }

  resetStore = () => {
    this.codeWasSent = false
    this.info = null
    this.userEmailStore = ''
    this.loading = false
    this.page = 'start'
    this.timeLeft = 5
    if (this.countdown) clearInterval(this.countdown)
  }
}
