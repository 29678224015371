import { ReactComponent as IconStar } from 'assets/images/star.svg'
import { ReactComponent as PlusIcon } from 'assets/images/plus_white.svg'
import { ReactComponent as MinusIcon } from 'assets/images/minus_black.svg'

import { Tooltip } from 'antd'
import { Link } from 'components/common/Button/Link'

import { Button } from 'components/common/Button/Button'

import './ListPageHeading.scss'
interface Props {
  title?: string | null | undefined
  isWatchList?: boolean
  children?: React.ReactNode
  editable?: string
  linkText?: string
  redirectPath?: string
  button?: boolean
  buttonStyle?: boolean
  buttonDisabled?: boolean

  onClick?: () => void
}

export const ListPageHeading = ({
  title,
  isWatchList,
  children,
  editable,
  linkText,
  redirectPath,
  button,
  buttonStyle, //true for showing promary button false for showing secondary button
  onClick,
  buttonDisabled,
}: Props): JSX.Element => {
  return (
    <section className='c-list-page-heading'>
      {isWatchList && <IconStar className='c-list-page-heading__star' />}

      <h1 className='c-list-page-heading__title'>{title}</h1>
      {linkText && !button && (
        <Tooltip placement='right' title={editable === 'false' ? `You are not allowed to edit this ` : ''}>
          <Link type='primary-1' to={editable === 'false' ? '#' : redirectPath || '#'}>
            <div className='c-list-page-heading__child'>
              <PlusIcon className='c-list-page-heading__child-icon' />
              {linkText}
            </div>
          </Link>
        </Tooltip>
      )}
      {linkText && button && (
        <>
          {' '}
          {buttonStyle && (
            <Button disabled={buttonDisabled} onClick={onClick} type={'primary-1'}>
              <div className='c-list-page-heading__child'>
                {buttonStyle && <PlusIcon className='c-list-page-heading__child-icon' />}
                {!buttonStyle && <MinusIcon className='c-list-page-heading__child-icon' />}
                {linkText}
              </div>
            </Button>
          )}
          {!buttonStyle && (
            <Button disabled={buttonDisabled} onClick={onClick} type={'primary-2'}>
              <div className='c-list-page-heading__child'>
                {buttonStyle && <PlusIcon className='c-list-page-heading__child-icon' />}
                {!buttonStyle && <MinusIcon className='c-list-page-heading__child-icon' />}
                {linkText}
              </div>
            </Button>
          )}
        </>
      )}
      {children}
    </section>
  )
}
