import axios from 'axios'

export const genericShare = (
  id: string,
  userIds: string[],
  type: 'narrative' | 'community' | 'tag',
  action: 'apply',
  permission_level: string,
) => {
  return axios.post(`/${type}/${id}/share`, { user_ids: userIds, action, permission_level })
}
