import { Spin, Tabs } from 'antd'
import { observer } from 'mobx-react-lite'

import { VIDEOS_SUMMARY_COLUMNS } from 'settings/settings'
import { store } from 'store'
import { Card } from 'components/common'
import { TableCustom } from 'components/common/Tables/TableCustom'
import { VideoThumbnail } from 'components/common/VideoThumbnail/VideoThumbnail'
import { IVideo } from 'models/models'

export const DefineNarrativeTable = observer(() => {
  const { loaderStore, defineNarrativeStore } = store

  const { contentNumMillify, videosTableData, videosTablePagination, updatePagination } = defineNarrativeStore

  return (
    <Card variant='0'>
      <Spin spinning={loaderStore.isLoadingVideos || loaderStore.isLoadingCreators || loaderStore.isLoadingCommunities}>
        <Tabs className='c-tabs'>
          <Tabs.TabPane tab={`Content ( ${contentNumMillify} )`} key='1'>
            <Spin spinning={loaderStore.isLabelingVideo}>
              <TableCustom
                pagination={videosTablePagination}
                onTableChange={updatePagination}
                data={videosTableData}
                columns={[
                  {
                    dataIndex: 'col1',
                    render: (col: IVideo) => <VideoThumbnail canBeLabeled={false} video={col} />,
                    width: '65%',
                  },
                  ...VIDEOS_SUMMARY_COLUMNS,
                ]}
              />
            </Spin>
          </Tabs.TabPane>
        </Tabs>
      </Spin>
    </Card>
  )
})
