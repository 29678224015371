import { Card } from 'components/common'
import { TableCustom } from 'components/common/Tables/TableCustom'
import { editColumn } from 'components/common/EditColumn/EditColumn'
import { MANAGE_NARRATIVES_TABLE } from 'settings/settings'
import { manageInfoColumn } from 'components/Manage/ManageInfoColumn/ManageInfoColumn'
import { ManageViewModal } from 'components/Manage/ManageViewModal/ManageViewModal'
import { ShareModal } from 'components/common/ShareModal/ShareModal'
import { TableTags } from 'components/common/TableTags/TableTags'

import { Spin, Tabs } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useEffectOnce } from 'react-use'

import { store } from 'store'

export const ManageNarrativesTable = observer(() => {
  const { manageNarrativesStore, userStore, loaderStore, usersStore } = store
  const {
    openView,
    isViewModalOpen,
    setIsViewModalOpen,
    openModal,
    isShareModalOpen,
    setIsShareModalOpen,
    paginationTableTab1,
    paginationTableTab2,
    paginationTableTab3,
    tableTabData1,
    tableTabData2,
    tableTabData3,
    setUserID,
    fetchData,
    updatePagination,
    viewModalData,
    lightTags,
    getLightTags,
    createTag,
    actionTag,
    shareItem,
  } = manageNarrativesStore

  const { users } = usersStore
  const { userId } = userStore

  useEffect(() => {
    setUserID(userId)
  }, [userId])

  useEffect(() => {
    fetchData({ tab: 1 })
  }, [paginationTableTab1])

  useEffect(() => {
    fetchData({ tab: 2 })
  }, [paginationTableTab2])

  useEffect(() => {
    fetchData({ tab: 3 })
  }, [paginationTableTab3])

  useEffectOnce(() => {
    getLightTags()
    usersStore.fetchUsers()
  })

  const tableColumn = [
    manageInfoColumn({
      type: 'narrative',
      onClickView: (id: number) => {
        openView(id)
      },
    }),
    {
      title: 'tags',
      dataIndex: 'col2',
      render: (col: any) => (
        <TableTags
          actionTag={actionTag}
          itemData={{ type: 'narrative', id: col.itemID }}
          createTag={createTag}
          tags={col.tags}
          allTags={lightTags}
        />
      ),
      width: '28%',
      align: 'left',
    },
    ...MANAGE_NARRATIVES_TABLE,
    editColumn({ type: 'narrative', openModal }),
  ]

  return (
    <section className='c-full'>
      <Card variant='1'>
        <Spin size='large' spinning={loaderStore.isLoadingNarratives || loaderStore.isLoadingTags}>
          <Tabs className='c-tabs'>
            <Tabs.TabPane tab={`My Narratives`} key='1'>
              <TableCustom data={tableTabData1} columns={tableColumn} />
            </Tabs.TabPane>

            <Tabs.TabPane tab={`What's New`} key='2'>
              <TableCustom
                data={tableTabData2}
                columns={tableColumn}
                pagination={paginationTableTab2}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 2, pagination })
                }}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={`All`} key='3'>
              <TableCustom
                data={tableTabData3}
                columns={tableColumn}
                pagination={paginationTableTab3}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 3, pagination })
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </Card>
      <ManageViewModal
        openModal={openModal}
        isLoading={loaderStore.isLoadingNarratives}
        type='narrative'
        data={viewModalData}
        isVisible={isViewModalOpen}
        setIsModalVisible={setIsViewModalOpen}
      />
      <ShareModal
        isVisible={isShareModalOpen}
        type='narrative'
        setIsModalVisible={setIsShareModalOpen}
        users={users}
        userID={userId}
        shareItem={shareItem}
      />
    </section>
  )
})
