import { Modal } from 'antd'

interface Props {
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
  type: 'narrative' | 'tag' | 'community'
  deleteItem: (
    type: 'narrative' | 'tag' | 'community',
    id: string | null | undefined,
    setIsModalVisible: (state: boolean) => void,
    afterCloseOperation: () => void,
  ) => Promise<void>
  id: string | undefined | null
  isLoading?: boolean
  afterCloseOperation: () => void
}
export const DeleteModal = ({
  isVisible,
  setIsModalVisible,
  type,
  deleteItem,
  id,
  isLoading,
  afterCloseOperation,
}: Props) => {
  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      title={`Delete ${type}`}
      wrapClassName='c-share-modal'
      visible={isVisible}
      closable
      confirmLoading={isLoading}
      centered
      onOk={() => {
        deleteItem(type, id, setIsModalVisible, afterCloseOperation)
      }}
      okType={'danger'}
      onCancel={() => setIsModalVisible(false)}
      okText='Delete tag'
      cancelText='Cancel'>
      <p className='c-share-modal__subtitle'>You are going to delete this tag. Are you sure?</p>
    </Modal>
  )
}
