import { FullListItem, IListItem } from 'models/models'
import { Divider } from 'antd'

import { ReactComponent as Ascending } from 'assets/images/Ascending.svg'
import { ReactComponent as Descending } from 'assets/images/Descending.svg'

import { MonitorGraph } from '../MonitorGraph/MonitorGraph'
import { MonitorHeatMap } from '../MonitorHeatMap/MonitorHeatMap'

import './MonitorListItem.scss'
interface Props {
  data: IListItem
  advancedData?: FullListItem
  isSelected: boolean
  isLoading: boolean
  openItem: (id: string) => void
  liteCommunities: {
    id: string
    name: string
  }[]
}

export const MonitorListItem = ({ data, advancedData, isSelected, isLoading, openItem, liteCommunities }: Props) => {
  return (
    <div className='c-montitor-list-item'>
      <div
        className='c-montitor-list-item__click-area'
        onClick={() => {
          openItem(isSelected ? '-1' : data.id)
        }}>
        <Divider className='c-montitor-list-item__divider' />
        <div className='c-montitor-list-item__info'>
          <div className='c-montitor-list-item__text'>{data.name}</div>
          {data.impresionsMilified !== '-1' && (
            <div className='c-montitor-list-item__impresions'>
              <span className='c-montitor-list-item__impresions-text'>{data.impresionsMilified}</span>
              <span className='c-montitor-list-item__impresions-icons'>
                {data.trend === 'positive' ? <Ascending /> : <Descending />}
              </span>
            </div>
          )}
        </div>
      </div>

      {isSelected && <MonitorGraph data={advancedData?.graphData} trend={advancedData?.change_impression_direction} />}
      {isSelected && <MonitorHeatMap data={advancedData?.heatMapData} communities={liteCommunities} />}
    </div>
  )
}
