import { v4 as uuidv4 } from 'uuid'

import millify from 'millify'

export function getNarrativeTableData({ source }: { source: any }) {
  return source?.map((item: any) => ({
    col1: {
      title: item.title,
      mentions: millify(item.mentions || 0),
      threats: millify(item.threats || 0),
    },
    col2: {
      impressions: millify(item.impressions || 0),
      change: item.change,
    },
    col3: {
      change: item.change,
    },
    key: uuidv4(),
  }))
}
