import { getRandomInt } from './getRandomInt'

export const getRandomColor = ({ colorList }: { colorList?: string[] } = {}) => {
  if (colorList && colorList.length) {
    const index = getRandomInt({ max: colorList.length })
    return colorList[index]
  }

  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}
