import { Button } from 'components/common/Button/Button'
import './DefineNarrativeActions.scss'

interface Props {
  editMode: boolean
  cancel: () => void
  publish: () => void
  save: () => void
  showPublish?: boolean
  edit: () => void
}

export const DefineNarrativeActions = ({
  cancel,
  publish,
  save,
  showPublish = true,
  editMode = false,
  edit,
}: Props) => {
  return (
    <div className='c-define-narrative-actions'>
      <div className='c-define-narrative-actions__wraper'>
        <div className='c-define-narrative-actions__left'>
          <Button
            type='primary-1'
            className='c-define-narrative-actions__button--save'
            onClick={editMode ? edit : save}>
            Save
          </Button>
        </div>

        <div className='c-define-narrative-actions__right'>
          <Button type='primary-1' disabled={!(showPublish && !editMode)} onClick={publish}>
            Publish
          </Button>

          <Button type='primary-2' className='c-define-narrative-actions__button--cancel' onClick={cancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}
