import { Col, Row } from 'antd'

import './ListPageSection.scss'

interface Props {
  variant: '1' | '2'
  content?: JSX.Element
  right?: JSX.Element
  left?: JSX.Element
  [x: string]: any
}

export const ListPageSection = ({ variant, content, right, left, ...rest }: Props): JSX.Element => {
  return (
    <>
      {variant === '1' && (
        <section {...rest} className='c-page-section'>
          {content}
        </section>
      )}

      {variant === '2' && (
        <section {...rest} className='c-page-section'>
          <Row gutter={28}>
            <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              {left}
            </Col>

            <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              {right}
            </Col>
          </Row>
        </section>
      )}
    </>
  )
}
