import { Auth } from 'aws-amplify'
import { Form, Spin } from 'antd'

import { ResetPassStage1 } from '../../ResetPassword/Stage1/ResetPassStage1'
import { ResetPassStage2 } from '../../ResetPassword/Stage2/ResetPassStage2'
import { ResetPassStage3 } from '../../ResetPassword/Stage3/ResetPassStage3'

import { Card } from 'components/common'

import './ResetPass.scss'
import { ReactComponent as IconPendulumLogo } from 'assets/images/pendulum-logo.svg'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useUnmount } from 'react-use'

export const ResetPass = observer(() => {
  const { resetPasswordStore } = store
  const {
    info,
    userEmailStore,
    timeLeft,
    loading,
    page,
    resetStore,
    setLoading,
    changePage,
    setInfo,
    setUserEmail,
    startContDown,
  } = resetPasswordStore

  async function resetPassword(values: any) {
    const { userEmail, code, newPassword }: { userEmail: string; code: string; newPassword: string } = values

    if (page === 'new_password') {
      try {
        setLoading(true)

        await Auth.forgotPasswordSubmit(userEmailStore, code, newPassword)

        setLoading(false)
        changePage('succes')
        setInfo(null)
      } catch (error: any) {
        setLoading(false)
        setInfo({ message: error.message, code: error.code, name: error.name, type: 'error' })
      }
    } else if (page === 'start') {
      try {
        setLoading(true)

        await Auth.forgotPassword(userEmail)

        setUserEmail(userEmail)
        changePage('new_password')
        setLoading(false)
        setInfo(null)
      } catch (error: any) {
        setLoading(false)
        setInfo({ message: error.message, code: error.code, name: error.name, type: 'error' })
      }
    }
  }

  useUnmount(() => {
    resetStore()
  })

  return (
    <div className='c-reset-pass' data-testid='reset-pass'>
      <Card variant='2'>
        <Spin size='large' spinning={loading}>
          <IconPendulumLogo className='c-reset-pass__logo' />

          <Form layout='vertical' onFinish={resetPassword}>
            {page === 'start' && <ResetPassStage1 info={info} />}
            {page === 'new_password' && <ResetPassStage2 info={info} />}
            {page === 'succes' && <ResetPassStage3 timeLeft={timeLeft} startTimer={startContDown} />}
          </Form>
        </Spin>
      </Card>
    </div>
  )
})
