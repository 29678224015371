import './wdyr'

import React from 'react'
import ReactDOM from 'react-dom'
import { Amplify } from 'aws-amplify'
import axios from 'axios'

import reportWebVitals from './reportWebVitals'
import { cognitoConfig } from 'configs/cognito.config'
import App from './App'

import './index.css'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: cognitoConfig.REGION,
    userPoolId: cognitoConfig.USER_POOL_ID,
    identityPoolId: cognitoConfig.IDENTITY_POOL_ID,
    userPoolWebClientId: cognitoConfig.APP_CLIENT_ID,
  },
})

axios.defaults.baseURL = process.env.REACT_APP_API

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// TODO: Start using web vitals

reportWebVitals()
