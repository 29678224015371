import { Modal } from 'antd'
import { useState } from 'react'

import { IVideo } from 'models/models'
import { UtilService } from 'services/Util/Util'
import RumbleLogo from 'assets/images/rumble-full-logo.svg'
import PodcastsLogo from 'assets/images/microphone-2.svg'
import TagManager from 'react-gtm-module'

import './VideoThumbnail.scss'
import { ReactComponent as IconPlayVideo } from 'assets/images/play-video.svg'

interface Props {
  canBeLabeled?: boolean
  video: IVideo
  labelVideo?: ({ videoId, label }: { videoId: string; label: 'relevant' | 'not_relevant' }) => void
}

export const VideoThumbnail = ({ canBeLabeled, video, labelVideo }: Props): JSX.Element => {
  const { videoId, videoTitle, impressionsMillify, platform, uploadDate, snippets, mediaUrl } = video

  const createTagManagerEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'video_thumbnail_click',
        video_thumbnail_click_video_id: videoId,
        video_thumbnail_click_platform: platform,
        video_thumbnail_click_video_title: videoTitle,
      },
    })
  }

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const videoSrc = UtilService.getVideoURL({ platform, videoId })

  const snippetsFull = UtilService.getSnippetsFull({ snippets, platform, videoId })

  const snippetsShort = UtilService.truncate({
    input: snippetsFull,
    size: 400,
  })

  const snippetToGTM = (el: any) => {
    if (!el.target?.href) return
    const href = el.target.href
    const url = new URL(href)
    const offset = url.searchParams.get('t')

    TagManager.dataLayer({
      dataLayer: {
        event: 'video_snippet_click',
        video_snippet_click_platform: platform,
        video_snippet_click_video_id: videoId,
        video_snippet_click_offset: offset,
        video_snippet_click_video_title: videoTitle,
      },
    })
  }

  return (
    <div className='c-video-thumbnail'>
      <div className='c-video-thumbnail__left'>
        {platform === 'YouTube' || platform === 'Rumble' || platform === 'Podcast' ? (
          <div
            onClick={() => {
              createTagManagerEvent()
              window.open(
                platform === 'YouTube'
                  ? `https://www.youtube.com/watch?v=${videoId}`
                  : platform === 'Rumble'
                  ? `https://rumble.com/${videoId}`
                  : platform === 'Podcast'
                  ? mediaUrl !== null
                    ? mediaUrl
                    : ''
                  : '',
                '_blank',
              )
            }}
            className='c-video-thumbnail__video'>
            <img
              className='c-video-thumbnail__video-img'
              src={
                platform === 'YouTube'
                  ? `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`
                  : platform === 'Rumble'
                  ? RumbleLogo
                  : PodcastsLogo
              }
              alt={video.videoTitle}></img>

            {platform !== 'Podcast' && <IconPlayVideo className='c-video-thumbnail__video-play-button' />}
          </div>
        ) : (
          <iframe
            onClick={() => {
              createTagManagerEvent()
            }}
            className='c-video-thumbnail__iframe'
            src={videoSrc}
            title={`${platform} video player`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen></iframe>
        )}
      </div>

      <div className='c-video-thumbnail__right'>
        <p className='c-video-thumbnail__title '>{videoTitle}</p>

        <p className='c-video-thumbnail__subtitle'>
          <strong>{impressionsMillify !== '0' ? impressionsMillify : 'N/A'}</strong> impressions | {platform} |{' '}
          {uploadDate}
        </p>

        <div className='c-video-thumbnail__description'>
          <div
            onClick={snippetToGTM}
            className='c-video-thumbnail__description-html'
            dangerouslySetInnerHTML={{ __html: snippetsShort || '' }}></div>

          <span className='c-video-thumbnail__show-more' onClick={() => setIsModalVisible(true)}>
            more
          </span>

          <Modal
            width={800}
            bodyStyle={{ maxHeight: '500px', overflow: 'auto' }}
            title='Snippets'
            wrapClassName='c-video-thumbnail__modal'
            visible={isModalVisible}
            closable
            centered
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}>
            <div onClick={snippetToGTM} dangerouslySetInnerHTML={{ __html: snippetsFull || '' }}></div>
          </Modal>
        </div>
      </div>
    </div>
  )
}
