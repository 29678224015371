import { ListPage } from 'components/common/ListPage/ListPage/ListPage'
import { ListPageHeading } from 'components/common/ListPage/ListPageHeading/ListPageHeading'
import { ListPageSection } from 'components/common/ListPage/ListPageSection/ListPageSection'
import { ManageTagsTable } from 'components/Manage/ManageTags/ManageTagsTable/ManageTagsTable'

export const ManageTagsPage = () => {
  return (
    <ListPage>
      <ListPageHeading title={'Tags'} />
      <ListPageSection variant='1' content={<ManageTagsTable />} />
    </ListPage>
  )
}
