import { Link, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import './Footer.scss'

export const Footer = () => {
  const location = useLocation()
  const isVisible = !location?.pathname?.includes('/define-')

  return isVisible ? (
    <footer className='c-footer'>
      <ul className='c-footer__list'>
        <li className='c-footer__list-item'>&#169; {dayjs().format('YYYY')} Pendulum</li>

        <li className='c-footer__list-item'>
          <Link className='c-footer__link' to='/'>
            Home
          </Link>
        </li>

        <li className='c-footer__list-item'>
          <Link className='c-footer__link' to='/terms'>
            Terms of service
          </Link>
        </li>

        <li className='c-footer__list-item'>
          <Link className='c-footer__link' to='/privacy'>
            Privacy Policy
          </Link>
        </li>

        <li className='c-footer__list-item'>
          <Link className='c-footer__link' to='/feedback'>
            Send feedback
          </Link>
        </li>
      </ul>
    </footer>
  ) : (
    <></>
  )
}
