import { Row, Col } from 'antd'

import { Profile } from 'components/Profile/Profile/Profile'
import { NavigationSettings } from 'components/common/Navigation/NavigationSettings/NavigationSettings'
import { ListPage } from 'components/common/ListPage/ListPage/ListPage'

export function ProfilePage(): JSX.Element {
  return (
    <ListPage>
      <Row>
        <Col span={6}>
          <NavigationSettings />
        </Col>

        <Col span={18}>
          <Profile />
        </Col>
      </Row>
    </ListPage>
  )
}
