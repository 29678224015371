import { get } from './get.api'
import { getUsers } from './users/getUsers.api'
import { getRoles } from './users/getRoles.api'
import { saveUser } from './users/saveUser.api'
import { deleteUser } from './users/deleteUser.api'
import { saveUserInSnowflake } from './users/saveUserInSnowflake.api'
import { updateUserRole } from './users/updateUserRole.api'
import { updateUserRolePendService } from './users/updateUserRolePendService.api'
import { publishNarrative } from './defineNarrativeNew/publishNarrative.api.'
import { saveNarrative } from './defineNarrativeNew/saveNarrative.api.'
import { getSnippets } from './defineNarrativeNew/getSnippets.api.'
import { getMatchingResultsNew } from './defineNarrativeNew/getMatchingResultsNew.api'
import { changeFollowingStatus } from './tagAPI/changeFollowingStatus'
import { submitCommunity } from './communityAPI/submitCommunity'
import { getSummaryTags } from './tagAPI/getSummaryTags'
import { createTag } from './tagAPI/createTag'
import { addTag } from './tagAPI/addTag'
import { addOrRemoveTag } from './tagAPI/addOrRemoveTag'
import { loadFeed } from './monitor/feeds.api'
import { editNarrative } from './defineNarrativeNew/editNarrative.api'
import { genericShare } from './common/genericShare'
import { genericEdit } from './common/genericEdit'
import { genericDelete } from './common/genericDelete'
export const API = {
  get,
  getUsers,
  getRoles,
  saveUser,
  deleteUser,
  updateUserRole,
  updateUserRolePendService,
  saveUserInSnowflake,
  genericShare,
  genericEdit,
  genericDelete,
}

export const defineNarrativeAPI = {
  publishNarrative,
  saveNarrative,
  getSnippets,
  getMatchingResultsNew,
  editNarrative,
}

export const tagAPI = {
  changeFollowingStatus,
  getSummaryTags,
  createTag,
  addTag,
  addOrRemoveTag,
}

export const communityAPI = {
  submitCommunity,
}

export const monitorAPI = {
  loadFeed,
}
