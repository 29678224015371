import { observer } from 'mobx-react-lite'
import { ListPage } from 'components/common/ListPage/ListPage/ListPage'
import { ListPageHeading } from 'components/common/ListPage/ListPageHeading/ListPageHeading'
import { ListPageSection } from 'components/common/ListPage/ListPageSection/ListPageSection'
import { store } from 'store'
import { EditDropdown } from 'components/common/EditDropdown/EditDropdown'
import { ShareModal } from 'components/common/ShareModal/ShareModal'
import { ManageTagsDetailTable } from 'components/Manage/ManageTags/ManageTagsDetailsTable/ManageTagDetailsTable'
import { EditModal } from 'components/common/EditModal/EditModal'
import { DeleteModal } from 'components/common/DeleteModal/DeleteModal'
import { useNavigate } from 'react-router-dom'

export const ManageTagDetailsPage = observer(() => {
  const navigate = useNavigate()
  const { manageTagsStore, loaderStore, usersStore, userStore } = store
  const {
    getSelectedTagFollowingStatus,
    getSelectedTagName,
    getSelectedTagID,
    updateTagStatusForDetail,
    openModal,
    isShareModalOpen,
    setIsShareModalOpen,
    shareItem,
    isEditModalOpen,
    setIsEditModalOpen,
    editItem,
    handleEditCloseModal,
    setIsDeleteModalOpen,
    isDeleteModalOpen,
    deleteItem,
  } = manageTagsStore
  const { users } = usersStore
  const { userId } = userStore
  const handleDeleteCloseModal = () => {
    console.log('ther is an function wherer it is in')
    return navigate('/manage/tags', { replace: true })
  }
  return (
    <>
      <ListPage>
        {' '}
        <ListPageHeading
          title={getSelectedTagName}
          buttonStyle={getSelectedTagFollowingStatus ? false : true}
          onClick={() => updateTagStatusForDetail(getSelectedTagID, !getSelectedTagFollowingStatus)}
          linkText={getSelectedTagFollowingStatus ? 'Unfollow' : 'Follow'}
          button={true}
          buttonDisabled={loaderStore.isLoadingTags}
          redirectPath={''}>
          <EditDropdown
            overlayStyle={{ width: '10%' }}
            openModal={openModal}
            name={'tags'}
            variant='2'
            id={getSelectedTagID}
          />
        </ListPageHeading>
        {<ListPageSection variant='1' content={<ManageTagsDetailTable />} />}
      </ListPage>

      <ShareModal
        isVisible={isShareModalOpen}
        type='tag'
        setIsModalVisible={setIsShareModalOpen}
        users={users}
        userID={userId}
        shareItem={shareItem}
      />
      <EditModal
        isVisible={isEditModalOpen}
        type='tag'
        setIsModalVisible={setIsEditModalOpen}
        item={{ id: getSelectedTagID, name: getSelectedTagName }}
        isLoading={loaderStore.isLoadingTags}
        editItem={editItem}
        afterAsyncOperation={handleEditCloseModal}
      />
      <DeleteModal
        isVisible={isDeleteModalOpen}
        type='tag'
        setIsModalVisible={setIsDeleteModalOpen}
        id={getSelectedTagID}
        isLoading={loaderStore.isLoadingTags}
        deleteItem={deleteItem}
        afterCloseOperation={handleDeleteCloseModal}
      />
    </>
  )
})
