import axios from 'axios'

import { ROUTES } from 'settings/settings'
interface Props {
  id: string
  name: string
  description: string
  communities: string[]
  keywords_expression: string
  parent_narrative_ids: string[]
  related_contents?: string[]
  tenant_id: string
  type: 'keyword'
}

export const editNarrative = async (narrative: Props) => {
  let url =
    process.env.REACT_APP_API +
    '/' +
    ROUTES.newNarrativeSave.split('/')[1] +
    `/${narrative.id}` +
    '/' +
    ROUTES.newNarrativeSave.split('/')[2]

  const { data } = await axios.post(url, narrative)

  return data
}
