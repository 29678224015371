import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { GraphData } from 'models/models'
import { useRef } from 'react'
import { Line, getElementsAtEvent } from 'react-chartjs-2'

interface Props {
  data?: GraphData
  trend?: 'positive' | 'negative'
}

export const MonitorGraph = ({ data, trend }: Props) => {
  const chartRef = useRef()

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

  const options = {
    aspectRatio: 3.3,
    responsive: true,
    plugins: {
      legend: {
        align: 'start' as const,
        position: 'bottom' as const,
        labels: {
          lineWidth: 3,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  const color = trend === 'positive' ? 'rgba(106, 192, 101, 1)' : 'rgba(255, 114, 105, 1)'

  const labels = data?.data_points.map((el, index) => index) || []

  const graphData = {
    labels,
    datasets: [
      {
        pointHitRadius: 16,
        label: 'Impressions dynamics',
        data: data?.data_points.map((el) => el.impression_delta),
        borderColor: color,
        backgroundColor: color,
        pointRadius: 0,
        cubicInterpolationMode: 'monotone' as const,
        parsing: {
          yAxisKey: 'net',
        },
      },
      {
        label: 'Average',
        data: data?.data_points.map((el) => el.running_average),
        borderColor: 'rgba(214, 183, 123, 1)',
        backgroundColor: '#fff',
        pointRadius: 0,
        borderWidth: 3,
        borderDash: [6, 6],
      },
    ],
  }

  return (
    <Line
      ref={chartRef}
      options={options}
      data={graphData}
      onClick={(e) => {
        if (!chartRef?.current) return
        var activePoints = getElementsAtEvent(chartRef.current, e)
        if (activePoints[0]) console.log(activePoints[0]?.index)
      }}
    />
  )
}
