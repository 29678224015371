import { UserStore } from './user.store'
import { UsersStore } from './users.store'
import { LoaderStore } from './loader.store'
import { DefineNarrativeStore } from './defineNarrative.store'
import { ResetPasswordStore } from './resetPassword.store'
import { ManageCommunitiesStore } from './manage/manageCommunities.store'
import { ManageNarrativesStore } from './manage/manageNarratives.store'
import { ManageTagsStore } from './manage/manageTags.store'
import { MonitorTagsStore } from './monitor/monitorTags.store'

class RootStore {
  userStore
  loaderStore

  defineNarrativeStore

  resetPasswordStore

  manageCommunitiesStore
  manageNarrativesStore
  manageTagsStore

  monitorTags

  usersStore

  constructor() {
    this.userStore = new UserStore()
    this.loaderStore = new LoaderStore()
    this.defineNarrativeStore = new DefineNarrativeStore()
    this.resetPasswordStore = new ResetPasswordStore()

    this.manageCommunitiesStore = new ManageCommunitiesStore()
    this.manageNarrativesStore = new ManageNarrativesStore()
    this.manageTagsStore = new ManageTagsStore()

    this.monitorTags = new MonitorTagsStore()

    this.usersStore = new UsersStore()
  }
}

export const store = new RootStore()
