import { TableCustom } from 'components/common/Tables/TableCustom'
import { editColumn } from 'components/common/EditColumn/EditColumn'
import { TAGS_DETAILED_COLUMNS } from 'settings/columns.settings'
import { Card } from 'components/common'
import { manageInfoColumn } from 'components/Manage/ManageInfoColumn/ManageInfoColumn'
import { FollowButton } from 'components/common/FollowButton/FollowButton'

import { Spin, Tabs } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { store } from 'store'
import { useEffectOnce } from 'react-use'
import { ShareModal } from 'components/common/ShareModal/ShareModal'
import { useNavigate } from 'react-router-dom'

export const ManageTagsTable = observer(() => {
  const { manageTagsStore, loaderStore, usersStore, userStore } = store
  const {
    openModal,
    tableTabData1,
    tableTabData2,
    tableTabData3,
    paginationTableTab1,
    paginationTableTab2,
    paginationTableTab3,
    updatePagination,
    fetchData,
    updateTagStatus,
    isShareModalOpen,
    setIsShareModalOpen,
    shareItem,
    setSeletecTagID,
    setSelectedTagName,
    setSelectTagFollowingStatus,
  } = manageTagsStore

  const { users } = usersStore
  const { userId } = userStore
  const navigate = useNavigate()
  useEffect(() => {
    fetchData({ tab: 1 })
  }, [paginationTableTab1])

  useEffect(() => {
    fetchData({ tab: 2 })
  }, [paginationTableTab2])

  useEffect(() => {
    fetchData({ tab: 3 })
  }, [paginationTableTab3])

  useEffectOnce(() => {
    usersStore.fetchUsers()
  })

  const manageTagColumn = [
    manageInfoColumn({
      onClickView: (col: any) => {
        setSeletecTagID(col.id)
        setSelectedTagName(col.name)
        setSelectTagFollowingStatus(col.followingStatus)

        return navigate(`/manage/tag/${col.id}`, { replace: true })
      },
    }),
    ...TAGS_DETAILED_COLUMNS,
    {
      dataIndex: 'col6',
      render: (col: any) => (
        <FollowButton updateTagStatus={updateTagStatus} followingStatus={col.followingStatus} tagID={col.tagID} />
      ),
      align: 'left',
    },
    editColumn({ type: 'tags', openModal }),
  ]

  return (
    <section className='c-full'>
      <Card variant='1'>
        <Spin size='large' spinning={loaderStore.isLoadingTags}>
          <Tabs className='c-tabs'>
            <Tabs.TabPane tab={`All`} key='1'>
              <TableCustom
                data={tableTabData1}
                columns={manageTagColumn}
                pagination={paginationTableTab1}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 1, pagination })
                }}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Following`} key='2'>
              <TableCustom
                data={tableTabData2}
                columns={manageTagColumn}
                pagination={paginationTableTab2}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 2, pagination })
                }}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab={`New`} key='3'>
              <TableCustom
                data={tableTabData3}
                columns={manageTagColumn}
                pagination={paginationTableTab3}
                onTableChange={(pagination: any) => {
                  updatePagination({ tab: 3, pagination })
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </Card>
      <ShareModal
        isVisible={isShareModalOpen}
        type='tag'
        setIsModalVisible={setIsShareModalOpen}
        users={users}
        userID={userId}
        shareItem={shareItem}
      />
    </section>
  )
})
