import { ICommunity, INarrative } from 'models/models'
import { EditDropdown } from 'components/common/EditDropdown/EditDropdown'

import './EditColumn.scss'

interface Props {
  type: 'tags' | 'narrative' | 'community'
  summary?: boolean
  openModal: (modal: string) => void
  width?: string
}

export const editColumn = ({ type, openModal, width = '8%' }: Props) => {
  return {
    dataIndex: 'editColumn',
    width,
    render: (col: INarrative | ICommunity) => (
      <div className='c-edit-column'>
        <EditDropdown openModal={openModal} name={type} variant='2' id={col?.id} />
      </div>
    ),
  }
}
