import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Form, Input, Spin, Alert } from 'antd'
import { Link } from 'react-router-dom'

import { Card, Heading } from 'components/common'
import { Button } from 'components/common/Button/Button'

import './SignIn.scss'
import { ReactComponent as IconPendulumLogo } from 'assets/images/pendulum-logo.svg'
import { AmplifyAuthenticator } from '@aws-amplify/ui-react'

export function SignIn(): JSX.Element {
  const [showNewPasswordField, setShowNewPasswordField] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<{ message: string; name: string; code: string } | null>(null)

  async function onFinish(values: any) {
    const { userEmail, password, newpassword }: { userEmail: string; password: string; newpassword: string } = values

    try {
      setLoading(true)
      const user = await Auth.signIn(userEmail, password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setLoading(false)
        setShowNewPasswordField(true)
      }

      if (newpassword) {
        setLoading(true)
        await Auth.completeNewPassword(user, newpassword)
      }
    } catch (error: any) {
      setLoading(false)
      setError(error)
    }
  }

  return (
    <div className='c-sign-in' data-testid='sign-in'>
      {/* HACK
       Added this because we need it for cypress testing. See if there is a way to extract the required functionality from AmplifyAuthenticator to log in from cypress, and the app would recognize the login.
      */}
      <div style={{ display: 'none' }}>
        <AmplifyAuthenticator />
      </div>

      <Card variant='2'>
        <Spin size='large' spinning={loading}>
          <IconPendulumLogo className='c-sign-in__logo' />

          <Form layout='vertical' onFinish={onFinish}>
            <Heading level='1' variant='2'>
              Access your account
            </Heading>

            {error && <Alert message={error.message} type='error' />}

            <Form.Item label='Email' name='userEmail' rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input placeholder='name@mail.com' type='email' />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input.Password />
            </Form.Item>

            {showNewPasswordField && (
              <Form.Item
                label='New Password'
                name='newpassword'
                validateStatus={showNewPasswordField ? 'warning' : ''}
                help='Please enter your new password'>
                <Input.Password placeholder='Strong password' />
              </Form.Item>
            )}

            <Button htmlType='submit' type='primary-2'>
              Sign In
            </Button>

            <p className='c-sign-in__reset'>
              Don't remember your password? <Link to='/reset'>Reset password</Link>
            </p>
          </Form>
        </Spin>
      </Card>
    </div>
  )
}
