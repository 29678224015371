import { ReactComponent as IconDropdownWhite } from 'assets/images/dropdown-white.svg'
import { ReactComponent as IconDropdownGray } from 'assets/images/dropdown-gray.svg'

import { Menu, Dropdown } from 'antd'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import './EditDropdown.scss'

interface Props {
  id: string | null | undefined
  name?: string
  variant: '1' | '2'
  openModal: (modal: string, id: string | null) => void
  className?: string
  overlayStyle?: string | any
}

export const EditDropdown = ({
  id,
  name = 'community',
  variant,
  openModal,
  className,
  overlayStyle = '',
}: Props): JSX.Element => {
  const menu = (
    <Menu>
      {name === 'narrative' && (
        <Menu.Item key='0'>
          <Link to={`/edit-narrative/${id}`}>Edit</Link>
        </Menu.Item>
      )}

      <Menu.Item key='1'>
        <div
          onClick={() => {
            openModal('edit', id as string | null)
          }}>
          Edit tag
        </div>
      </Menu.Item>
      <Menu.Item key='2'>
        <div
          onClick={() => {
            openModal('delete', id as string | null)
          }}>
          Delete
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={() => {
            openModal('share', id as string | null)
          }}>
          Share
        </div>
      </Menu.Item>
    </Menu>
  )

  return id ? (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      className={classNames('c-edit-dropdown', className)}
      overlayStyle={overlayStyle}
      overlayClassName='c-edit-dropdown__overlay'>
      <button>
        {variant === '1' && <IconDropdownWhite />}
        {variant === '2' && <IconDropdownGray />}
      </button>
    </Dropdown>
  ) : (
    <> </>
  )
}
