import { Tooltip } from 'antd'
import { ReactComponent as Arrow } from 'assets/images/arrow-icon.svg'
import { ReactComponent as Statistics } from 'assets/images/statistics-icon.svg'
import classNames from 'classnames'
import { useState } from 'react'

import './ManageInfoColumn.scss'

interface Props {
  data: {
    name: string
    description: string
    isDraft: boolean
    type?: 'narrative' | 'community'
    onClickView?: () => void
  }
}

interface ColumnProps {
  onClickView: (id: number) => void
  type?: 'narrative' | 'community'
  width?: string
}

export const ManageInfo = ({ data }: Props) => {
  const [hovering, setHovering] = useState(false)

  let description =
    data?.description?.length > 40 ? (
      <Tooltip title={data.description} key={data.name}>
        {`${data.description.slice(0, 43)}...`}
      </Tooltip>
    ) : (
      data.description
    )
  return (
    <div
      onClick={data.onClickView}
      onMouseOver={() => {
        setHovering(true)
      }}
      onMouseOut={() => {
        setHovering(false)
      }}>
      <div className='c-manage-info__title'>
        <span>{data.name}</span>
        {data.type === 'narrative' && (
          <div className={classNames('c-manage-info__hover', { 'no-display': !hovering })}>
            <Arrow className='c-manage-info__hover-buttons' />
            {!data.isDraft && (
              <>
                |
                <Statistics className='c-manage-info__hover-buttons' />
              </>
            )}
          </div>
        )}
        {data.isDraft && (
          <div className='c-manage-info__draft__box'>
            <div className='c-manage-info__draft__circle'></div>
            <span className='c-manage-info__draft__text'>Draft</span>
          </div>
        )}
      </div>
      <p className='c-manage-info__description'>{description}</p>
    </div>
  )
}

export const manageInfoColumn = ({ onClickView, width = '35%', type = 'community' }: ColumnProps) => {
  return {
    dataIndex: 'col1',
    render: (col: any) => (
      <ManageInfo
        data={{
          onClickView: () => {
            onClickView(col)
          },
          ...col,
          type,
        }}
      />
    ),
    width,
    align: 'left',
  }
}
