import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Auth } from 'aws-amplify'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'
import { useEffectOnce } from 'react-use'
import { useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import TagManager from 'react-gtm-module'

import 'antd/dist/antd.css' // don't move it from here or the css will break. I need to investicate why

import { UtilService } from 'services/Util/Util'

import { AuthListener } from 'components/Auth/AuthListener/AuthListener'
import { PrivateRoute } from 'components/Auth/PrivateRoute/PrivateRoute'
import { AdminRoute } from 'components/Auth/AdminRoute/AdminRoute'
import { Navigation } from 'components/common/Navigation/Navigation/Navigation'

import { SignInPage } from 'pages/SignIn.page'
import { ResetPassPage } from 'pages/ResetPass.page'
import { ProfilePage } from 'pages/Profile.page'
import { UsersPage } from 'pages/Users.page'
import { TermsPage } from 'pages/Terms.page'
import { PrivacyPage } from 'pages/Privacy.page'
import { FeedbackPage } from 'pages/Feedback.page'
import { ManagePage } from 'pages/Manage.page'
import { MonitorPage } from 'pages/Monitor.page'
import { AnalysePage } from 'pages/Analyse.page'
import { Footer } from 'components/common/Footer/Footer'
import { DefineNarrative } from 'pages/DefineNarrative.page'

import { ManageNarrativesPage } from 'pages/Manage/ManageNarratives.page'
import { ManageTagsPage } from 'pages/Manage/ManageTags.page'
import { ManageCommunitiesPage } from 'pages/Manage/ManageCommunities.page'

import { MonitorTagsPage } from 'pages/Monitor/MonitorTags.page'

import { store } from 'store'
import 'styles/index.scss'
import './App.css'
import { ManageTagDetailsPage } from 'pages/Manage/ManageTagDetails.page'

dayjs.extend(utc)

axios.interceptors.request.use(
  async (config) => {
    const route = config?.url?.split('?')[0]

    // @ts-ignore
    store.loaderStore.setIsLoading({ route, isLoading: true })

    const token = await UtilService.getAuthToken()

    if (token) {
      config.headers['Authorization'] = token
    }

    return config
  },
  (error) => {
    const route = error.config?.url?.split('?')[0]
    // @ts-ignore
    store.loaderStore.setIsLoading({ route, isLoading: false })

    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    const route = response.config?.url?.split('?')[0]

    if (route?.includes('labelVideo')) {
      // @ts-ignore
      store.loaderStore.setIsLoading({ route, isLoading: false, extra: response?.config?.data })
    } else {
      // @ts-ignore
      store.loaderStore.setIsLoading({ route, isLoading: false })
    }

    return response
  },
  (error) => {
    const route = error.config?.url?.split('?')[0]
    // @ts-ignore
    store.loaderStore.setIsLoading({ route, isLoading: false })

    return Promise.reject(error)
  },
)

const App = observer((): JSX.Element => {
  const { userStore } = store
  const { userName } = userStore

  const [displayRouter, setDisplayRouter] = useState<boolean>(false)

  const createGTMLogInEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_login',
        //@ts-ignore
        user_id: userStore.userId,
        user_name: userStore.userName,
        tenantId: userStore.tenantId,
        roleId: userStore.roleId,
      },
    })
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()

      await userStore.checkAndSetCurrentRole()

      const userName = user.username
      const userEmail = user.attributes?.email
      const tenantId = user?.attributes['custom:tenant_id']
      const userId = user.attributes['custom:user_id']
      const roleId = user.attributes['custom:role_id']
      const subId = user.attributes?.sub
      const given_name = user.attributes?.given_name
      const family_name = user.attributes?.family_name
      userStore.setUser({ userName, userEmail, given_name, family_name, tenantId, userId, roleId, subId })

      createGTMLogInEvent()
    } catch (error) {
      userStore.setUser({
        userName: null,
        userEmail: null,
        given_name: null,
        family_name: null,
        tenantId: null,
        userId: null,
        roleId: null,
        subId: null,
      })
    }

    setDisplayRouter(true)
  }

  useEffectOnce(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID || '',
    })

    checkUser()

    onAuthUIStateChange((nextAuthState, authData: any) => {
      if (authData && nextAuthState === AuthState.SignedIn) {
        const userName = authData?.username
        const userEmail = authData?.attributes?.email
        const tenantId = authData?.attributes['custom:tenant_id']
        const userId = authData?.attributes['custom:user_id']
        const roleId = authData?.attributes['custom:role_id']
        const subId = authData?.attributes?.sub
        const given_name = authData?.attributes?.given_name
        const family_name = authData?.attributes.family_name
        userStore.setUser({ userName, userEmail, family_name, given_name, tenantId, userId, roleId, subId })
      }
    })
  })

  return (
    <>
      <AuthListener />

      {displayRouter && (
        <>
          <Router>
            {userName && <Navigation />}

            <Routes>
              <Route index element={<PrivateRoute element={<Navigate to='/manage/tags' replace />} />} />

              <Route path='/signin' element={userName ? <Navigate replace to='/manage' /> : <SignInPage />} />

              <Route path='/' element={!userName && <Navigate replace to='/signin' />} />

              <Route path='/reset' element={!userName ? <ResetPassPage /> : <Navigate replace to='/manage' />} />

              <Route path='/add-narrative' element={<DefineNarrative />} />

              <Route path='/edit-narrative/:id' element={<DefineNarrative />} />

              <Route path='/settings/profile' element={<PrivateRoute element={<ProfilePage />} />} />

              <Route path='/settings/users' element={<AdminRoute element={<UsersPage />} />} />

              <Route path='/terms' element={<PrivateRoute element={<TermsPage />} />} />

              <Route path='/privacy' element={<PrivateRoute element={<PrivacyPage />} />} />

              <Route path='/feedback' element={<PrivateRoute element={<FeedbackPage />} />} />

              <Route path='/analyse/*' element={<PrivateRoute element={<AnalysePage />} />} />

              <Route path='/monitor/*' element={<PrivateRoute element={<MonitorPage />} />}>
                <Route path='tags' element={<MonitorTagsPage />} />
                <Route path='narratives' element={<>Narratives</>} />
                <Route path='communities' element={<>Communities</>} />
                <Route path='creators' element={<>Creators</>} />
              </Route>

              <Route path='/manage/*' element={<PrivateRoute element={<ManagePage />} />}>
                <Route path='tags' element={<ManageTagsPage />} />
                <Route path='tag/:id' element={<ManageTagDetailsPage />} />
                <Route path='narratives' element={<ManageNarrativesPage />} />
                <Route path='communities' element={<ManageCommunitiesPage />} />
              </Route>
            </Routes>

            <Footer />
          </Router>
        </>
      )}
    </>
  )
})

export default App
