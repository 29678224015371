import { Dropdown, Menu, Tag, Tooltip } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'

import { ILightTag } from 'models/models'
import { ReactComponent as IconCloseTag } from 'assets/images/close-tag.svg'

import './TagsList.scss'

interface Props {
  tags: string[]
  actionTag?: ({ action, tagId, id }: { action: 'apply' | 'remove'; tagId: string; id: string }) => void
  contentId?: string
  allTags?: ILightTag[]
}

export const TagsList = ({ allTags, tags, actionTag, contentId }: Props) => {
  const tagsList = (
    <Menu>
      {tags.map((tag: any) => {
        const isLongTag = tag.length > 20

        const keywordElement = (
          <Tag
            className='c-tags-list__item'
            closable
            closeIcon={<IconCloseTag className='c-tags-list__item__close-icon' />}
            onClose={() => {
              if (actionTag && contentId) {
                const tagID = allTags?.filter((el) => el.label === tag)[0]?.id
                if (tagID) actionTag({ action: 'remove', tagId: tagID, id: contentId })
              }
            }}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        )

        return (
          <Menu.Item className='c-tags-list__menu-item' key={uuidv4()}>
            {isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {keywordElement}
              </Tooltip>
            ) : (
              keywordElement
            )}
          </Menu.Item>
        )
      })}
    </Menu>
  )
  return (
    <Dropdown className='c-tags-list' overlay={tagsList} trigger={['hover']}>
      <Tag className={classNames('c-tags-list__item', 'c-tags-list__item--parent')} key={uuidv4()}>
        +{tags.length} Others
      </Tag>
    </Dropdown>
  )
}
