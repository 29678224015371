import { API, tagAPI } from 'api/api'
import { makeObservable, observable, action } from 'mobx'
import { UtilService } from 'services/Util/Util'
import { ManageStore } from './manage.store'

export class ManageTagsStore extends ManageStore {
  store: 'tag' | 'community' | 'narrative' = 'tag'
  selectedTagID: string | null | undefined = null
  selectedTagName: string | null | undefined = null
  selectedTagFollowingStatus: boolean = false

  narrative: any[] = []
  community: any[] = []
  creator: any[] = []
  post: any[] = []
  subjectedEntity: any = null

  setSeletecTagID = (id: string | null) => {
    this.selectedTagID = id
  }
  setSelectedTagName = (name: string | null) => {
    this.selectedTagName = name
  }
  setSelectTagFollowingStatus = (following: boolean) => {
    this.selectedTagFollowingStatus = following
  }
  get getSelectedTagName() {
    return this.selectedTagName
  }
  get getSelectedTagID() {
    return this.selectedTagID
  }
  get getSelectedTagFollowingStatus() {
    return this.selectedTagFollowingStatus
  }
  updateTagStatusForDetail = async (tagID: string | undefined | null, followingStatus: boolean) => {
    try {
      await tagAPI.changeFollowingStatus(tagID, followingStatus)
      this.selectedTagFollowingStatus = !this.selectedTagFollowingStatus
    } catch (e: any) {
      this.setSelectTagFollowingStatus(!this.selectedTagFollowingStatus)
      UtilService.openNotification({
        type: 'error',
        message: 'An error occured',
        description: 'An error occured wile changing the status of the tag, please try again later',
      })
    }
  }

  updateTagStatus = async (tagID: string | undefined | null, followingStatus: boolean) => {
    try {
      await tagAPI.changeFollowingStatus(tagID, followingStatus)

      for (let tab: 1 | 2 | 3 = 1; tab <= 3; tab++)
        if (tab === 1 || tab === 2 || tab === 3) {
          this.saveTableData({
            tab,
            data: this[`dataTableTab${tab}`].map((el: any) => {
              return el.id !== tagID ? el : { ...el, following: followingStatus }
            }),
          })
        }

      await this.fetchData({ tab: 2 })
      this.setSelectTagFollowingStatus(this.selectedTagFollowingStatus)
    } catch (e: any) {
      console.log('error')
      this.setSelectTagFollowingStatus(this.selectedTagFollowingStatus)
      UtilService.openNotification({
        type: 'error',
        message: 'An error occured',
        description: 'An error occured wile changing the status of the tag, please try again later',
      })
    }
  }

  get tableTabDetailsData1() {
    return UtilService.getTableData({ source: this.dataTableTab1, table: this.storeType })
  }
  get tableTabDetailsData2() {
    return UtilService.getTableData({ source: this.dataTableTab2, table: this.storeType })
  }
  get tableTabDetailsData3() {
    return UtilService.getTableData({ source: this.dataTableTab3, table: this.storeType })
  }
  get tableTabDetailsData4() {
    return UtilService.getTableData({ source: this.dataTableTab3, table: this.storeType })
  }

  fetchTagDetails = async (id: number | undefined | null | string) => {
    try {
      const { data } = await API.get({
        route: this.storeType,
        id,
        getError: true,
      })

      this.saveTagDetailsData(data)
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error during fetching single item data',
        description: e.message,
      })
    }
  }
  saveTagDetailsData = (data: any) => {
    this.narrative = data?.entity_details?.NARRATIVE
    this.creator = data?.entity_details?.CHANNEL
    this.post = data?.entity_details?.POST
    this.community = data?.entity_details?.COMMUNITY
  }
  get narrativesTableData() {
    return UtilService.getTagDetailsData({ source: this.narrative, type: 'narrative' })
  }
  get communitiesTableData() {
    return UtilService.getTagDetailsData({ source: this.community, type: 'community' })
  }
  get postsTableData() {
    return UtilService.getTagDetailsData({ source: this.post, type: 'post' })
  }
  get creatorsTableData() {
    return UtilService.getTagDetailsData({ source: this.creator, type: 'creator' })
  }
  actionToAddOrRemoveTag = async ({
    action,
    tagId,
    id,
    type,
  }: {
    action: 'remove' | 'apply'
    tagId: string
    id: string
    type: 'narrative' | 'community' | 'post' | 'creator'
  }) => {
    try {
      await tagAPI.addOrRemoveTag({ tagId, action, id, type })
      if (action === 'remove') {
        this.subjectedEntity = null

        this.subjectedEntity = this[type].filter((element) => element.id === id)[0]
        this[type] = this[type].filter((element) => element.id !== id)
      }
      if (action === 'apply') this[type].push(this.subjectedEntity)
    } catch (e) {
      UtilService.openNotification({
        type: 'error',
        message: `Error while ${action} the tab.`,
        description: `An error occured while ${action} the tag to the ${this.storeType}.`,
      })
    }
  }
  handleEditCloseModal = (label: string) => {
    this.selectedTagName = label
  }
  constructor() {
    super()
    makeObservable(this, {
      store: observable,
      selectedTagID: observable,
      selectedTagName: observable,
      selectedTagFollowingStatus: observable,
      narrative: observable,
      community: observable,
      creator: observable,
      post: observable,
      setSeletecTagID: action.bound,
      setSelectedTagName: action.bound,
      setSelectTagFollowingStatus: action.bound,
      updateTagStatusForDetail: action.bound,
      updateTagStatus: action.bound,
      saveTagDetailsData: action.bound,
      actionToAddOrRemoveTag: action.bound,
      handleEditCloseModal: action.bound,
    })
  }
}
