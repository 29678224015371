import { useRef } from 'react'
import { Form, Input, Select, Spin } from 'antd'
import { ReactComponent as IconInfo } from 'assets/images/icon-info.svg'

import { DefineNarrativeTable } from 'components/Define/DefineNarrative/DefineNarrativeTable/DefineNarrativeTable'
import { v4 as uuidv4 } from 'uuid'

import { ICommunityDropdown, INarrativeDropdown } from 'models/models'
import { Button } from 'components/common/Button/Button'
import { UtilService } from 'services/Util/Util'

import { DefineNarrativeMatchingResults } from 'components/Define/DefineNarrative/DefineNarrativeMatchingResults/DefineNarrativeMatchingResults'

interface Props {
  searchQuery: () => void
  matchingResults: number
  narrativeFilterData: INarrativeDropdown[]
  communityFilterData: ICommunityDropdown[]
  contentLoader: boolean
  form: any
  matchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany'
}

export const DefineNarrativeForm = ({
  searchQuery,
  matchingResults,
  narrativeFilterData,
  communityFilterData,
  contentLoader,
  form,
  matchingResultsState,
}: Props) => {
  const tooltipOptions = { icon: <IconInfo />, overlayStyle: { width: '600px' }, overlayInnerStyle: { width: '600px' } }

  const scrollRef = useRef<null | HTMLDivElement>(null)

  const scrollToTable = () => scrollRef?.current?.scrollIntoView({ behavior: 'smooth' })

  return (
    <>
      <h1 className='c-define__title'>Create new narrative</h1>
      <Form.Item
        className='c-define__item c-define__item--1'
        name='name'
        label='Give it a name'
        rules={[{ required: true, message: 'Required field' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        className='c-define__item c-define__item--1'
        name='description'
        label='Narrative description (optional)'>
        <Input />
      </Form.Item>
      <Form.Item
        className='c-define__item c-define__item--1'
        name='linkContent'
        label='Link to relevant content (optional)'
        rules={[{ type: 'url', message: 'Please write a valid URL.' }]}>
        <Input placeholder='https://' />
      </Form.Item>
      <Form.Item
        className='c-define__item c-define__item--1 margin-top'
        name='subset'
        label='Create as a subset of an existing narrative (optional)'
        tooltip={{
          title:
            'The narrative you are defining will be created as a subset of an existing narrative. This is useful when you are trying to search within an already well-defined existing topic, especially if that topic has machine learning applied to it.',
          ...tooltipOptions,
        }}>
        <Select mode='multiple'>
          {narrativeFilterData.map((narrative: any) => (
            <Select.Option key={uuidv4()} value={narrative.id}>
              {narrative.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className='c-define__item c-define__item--1'
        name='focusCommunities'
        label='Focus on specific communities (optional)'
        tooltip={{
          title:
            'You can use one or more communities as part of the narrative definition. The narrative will be limited to only content produced by the creators who are members of these communities. This is useful if you intend to use this narrative to alert and track a more specific scenario. E.g. Brand mentions by Qanon creators.',
          ...tooltipOptions,
        }}>
        <Select mode='multiple'>
          {communityFilterData.map((comm: any) => (
            <Select.Option key={uuidv4()} value={comm.id}>
              {comm.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <div ref={scrollRef} className='c-define-narrative__boolean-search'>
        <Form.Item
          className='c-define__item c-define__item--1 c-define-narrative__boolean-search-form'
          name='booleanSearch'
          label='Boolean keyword search'
          rules={[
            {
              required: true,
              message: 'There is an issue with your syntax.',
            },
            () => ({
              validator(_, value) {
                const stack: string[] = []
                const values = value.split('')
                let extra: boolean = false

                values.forEach((el: string) => {
                  if (extra) return
                  if (el === '(') return stack.push('(')
                  if (el === ')') {
                    if (stack.length === 0) {
                      extra = true
                    } else return stack.pop()
                  }
                })

                if (extra) return Promise.reject(new Error('You’re missing a “(” from your expression.'))

                if (value.match(/"/g)?.length % 2)
                  return Promise.reject(new Error(`You have an extra ' " ' in your expression.`))

                return stack.length > 0
                  ? Promise.reject(new Error('You’re missing a “)” from your expression.'))
                  : Promise.resolve()
              },
            }),
          ]}
          tooltip={{
            title: (
              <>
                Boolean operators allow you to more precisely define what you are looking for. You can use the following
                operators: <br />
                <ol type='i'>
                  <li>
                    <b>“search term”</b>:search of exact phrase. E.g. “march madness”
                  </li>
                  <li>
                    <b>AND</b>:search for presence of all terms in your search. E.g. “Arkansas AND Duke”
                  </li>
                  <li>
                    <b>OR</b>:search for presence of one term or another. E.g. “Arkansas OR Razorbacks”
                  </li>
                  <li>
                    <b>NOT</b>:remove items that contain a specific term. E.g. NOT (“1994 final”)
                  </li>
                  <li>
                    E.g. Narrowing down to the Elite 8 men’s basketball game in 2022 march madness tournament:
                    ((“Arkansas” OR “Razorbacks”) AND (“Duke” OR “Blue Devils”) AND “march madness”) NOT (“1994 final”)
                  </li>
                </ol>
              </>
            ),
            ...tooltipOptions,
          }}>
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
        </Form.Item>
        <Button
          type='primary-2'
          onClick={async () => {
            try {
              await form.validateFields()
              scrollToTable()
              searchQuery()
            } catch (e) {
              UtilService.openNotification({
                description: `Please fill all the required fields on the form before trying to search for content.`,
                message: `Form error`,
                type: 'error',
              })
            }
          }}
          className='c-define-narrative__boolean-search__button'>
          Search
        </Button>
      </div>

      <DefineNarrativeMatchingResults type={matchingResultsState} matchingResults={matchingResults} />

      <Spin spinning={contentLoader}>
        <DefineNarrativeTable />
      </Spin>
    </>
  )
}
