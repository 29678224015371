import { Auth } from 'aws-amplify'

import { LogService } from 'services/Log/Log'

export async function getAuthToken() {
  try {
    const user: any = await Auth.currentSession()
    return user?.idToken?.jwtToken
  } catch (error) {
    LogService.error({ message: 'error getAuthToken', error })
    return ''
  }
}
