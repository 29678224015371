import { DefineNarrativeActions } from 'components/Define/DefineNarrative/DefineNarrativeActions/DefineNarrativeActions'
import { DefineNarrativeForm } from 'components/Define/DefineNarrative/DefineNarrativeForm/DefineNarrativeForm'
import { DefineNarrativeModals } from 'components/Define/DefineNarrative/DefineNarrativeModals/DefineNarrativeModals'
import { Card } from 'components/common'

import { Alert, Form, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffectOnce, useUnmount } from 'react-use'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { store } from 'store'

import '../components/Define/Define.scss'

export const DefineNarrative = observer((): JSX.Element => {
  const { id: narrativeID } = useParams()
  const [form] = Form.useForm()

  const { defineNarrativeStore, userStore, loaderStore } = store

  const {
    formError,
    isLoadingNarrative,
    modalData,
    closeModal,
    matchingResults,
    addFormData,
    publishNarrative,
    saveNarrative,
    fetchDropdowns,
    narrativeFilterData,
    communityFilterData,
    resetStore,
    cancelNarrative,
    searchQuery,
    setTenantId,
    contentLoader,
    userSearched,
    matchingResultsState,
    enterEditMode,
    editNarrative,
  } = defineNarrativeStore

  const { isSendingDefineNarrrativeData, isLoadingNarratives } = loaderStore

  useEffectOnce(() => {
    fetchDropdowns()
    if (narrativeID) enterEditMode(narrativeID, form)
  })

  const publish = async () => {
    try {
      await form.validateFields()
      return publishNarrative()
    } catch (e: any) {}
  }

  useEffect(() => {
    if (userStore.tenantId) setTenantId(userStore.tenantId)
  }, [userStore.tenantId])

  useUnmount(() => {
    resetStore()
  })

  return (
    <Spin spinning={isSendingDefineNarrrativeData || isLoadingNarratives}>
      <section className='c-define-container'>
        <DefineNarrativeModals closeModal={closeModal} modalToShow={modalData} />

        <Card variant='1'>
          <Spin size='large' spinning={isLoadingNarrative}>
            {formError && <Alert message={formError} type='error' closeText='Close Now' />}

            <Form
              style={{ display: 'flex', flexDirection: 'column' }}
              form={form}
              layout='vertical'
              className='c-define c-define--1'
              onValuesChange={(newFormData: any) => {
                addFormData(newFormData)
              }}>
              <DefineNarrativeForm
                form={form}
                matchingResults={matchingResults}
                searchQuery={searchQuery}
                narrativeFilterData={narrativeFilterData}
                communityFilterData={communityFilterData}
                contentLoader={contentLoader}
                matchingResultsState={matchingResultsState}
              />
            </Form>

            <DefineNarrativeActions
              editMode={!!narrativeID}
              publish={publish}
              cancel={cancelNarrative}
              save={saveNarrative}
              showPublish={userSearched}
              edit={() => {
                editNarrative(narrativeID)
              }}
            />
          </Spin>
        </Card>
      </section>
    </Spin>
  )
})
