import { API, monitorAPI } from 'api/api'
import millify from 'millify'
import { action, makeObservable, observable } from 'mobx'
import { FullListItem, IListItem, ISnippet, SurpriseData, SourceData } from 'models/models'
import { LogService } from 'services/Log/Log'
import { UtilService } from 'services/Util/Util'

export class MonitorStore {
  storeType: 'tag' | 'narrative' | 'community' = 'tag'

  listItems: IListItem[] = []

  listItemsPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }

  openedItemData: FullListItem | null = null

  snippets: ISnippet[] = []
  totalSnippets: number = 0

  surpriseModalData: SurpriseData | null = null
  sourceModalData: SourceData | null = null

  surpriseVideos: SurpriseData[] = []

  avableFilters = {
    impresions: ['Max impressions', 'Min impressions'],
    timeSpan: ['Yesterday', 'Last 7 days', 'Last 30 days', 'Last 90 days'],
  }

  listFilter: {
    impresions: 'Max impressions' | 'Min impressions'
    timeSpan: 'Yesterday' | 'Last 7 days' | 'Last 30 days' | 'Last 90 days'
  } = { impresions: 'Max impressions', timeSpan: 'Yesterday' }

  loadedCreatorIds: string[] = []
  loadedCreators: any[] = []

  liteCommunities: { id: string; name: string }[] = []

  delay = async (time: number) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        return resolve('')
      }, time)
    })
  }

  avableSnippetsFilters = {
    type: ['New impressions', 'Total impressions', 'Risk score', 'Date'],
    mode: ['Ascending', 'Descending'],
  }

  snippetsFilter = { type: 'Total impressions', mode: 'Ascending' }

  constructor() {
    makeObservable(this, {
      listItems: observable,
      listFilter: observable,
      snippetsFilter: observable,
      snippets: observable,
      openedItemData: observable,
      surpriseModalData: observable,
      sourceModalData: observable,
      liteCommunities: observable,
      openItem: action.bound,
      setListItems: action.bound,
      setSnippets: action.bound,
      setSurpriseModalData: action.bound,
      setListFilter: action.bound,
      setSnippetsFilter: action.bound,
      resetStore: action.bound,
      setCommunities: action.bound,
      updateSnippets: action.bound,
      updateListItem: action.bound,
      setOpenedItemData: action.bound,
    })
  }

  openItem = async (id: string) => {
    try {
      const timeSpan = this.getTimeSpan()

      this.setSnippets([])
      this.setSnippetsTotal(0)

      if (id === '-1') {
        this.setOpenedItemData(null)
        return
      }

      const { data } = await API.get({ route: 'tag', id })

      const communities = data.entity_details.COMMUNITY.map((el: { id: string; name: string; number: string }) => {
        return el.number
      })
      const narratives = data.entity_details.NARRATIVE.map((el: { id: string; name: string; number: string }) => {
        return el.number
      })

      let narrativesString = ''
      narratives.forEach((narrative: string, index: number) => {
        narrativesString += narrative
        if (index < narratives.length - 1) narrativesString += ','
      })
      let communitiesString = ''
      communities.forEach((community: string, index: number) => {
        communitiesString += community
        if (index < communities.length - 1) communitiesString += ','
      })

      const [{ data: dataLineChart }, { data: dataHeatMap }] = await Promise.all([
        API.get({
          route: 'statistics',
          customAPI: process.env.REACT_APP_SEARCH_API,
          customProps: {
            narrative_ids: narrativesString,
            community_ids: communitiesString,
            start_date: timeSpan.startDate,
            end_date: timeSpan.endDate,
            chart_type: 'line',
          },
          isPromise: true,
        }),
        API.get({
          route: 'statistics',
          customAPI: process.env.REACT_APP_SEARCH_API,
          customProps: {
            narrative_ids: narrativesString,
            community_ids: communitiesString,
            start_date: timeSpan.startDate,
            end_date: timeSpan.endDate,
            chart_type: 'heat',
          },
          isPromise: true,
        }),
      ])

      this.updateListItem(id, {
        impresions: dataLineChart.total_change_impression_count,
        trend: dataLineChart.change_impression_direction,
      })

      this.fetchSnippets(narratives, communities)

      this.setOpenedItemData({
        id,
        total_change_impression_count: dataLineChart.total_change_impression_count,
        change_impression_direction: dataLineChart.change_impression_direction,
        graphData: {
          data_points: dataLineChart.data_points,
        },
        heatMapData: {
          data_points: dataHeatMap.data_points,
        },
      })

      this.getCommunities('snippets')
    } catch (e: any) {
      this.setOpenedItemData(null)

      UtilService.openNotification({
        type: 'error',
        message: 'Error wille gathering data for your ' + this.storeType,
        description: e.message,
      })
    }
  }

  setOpenedItemData = (data: FullListItem | null) => {
    this.openedItemData = data
  }

  updateListItem = (id: string, data: { impresions: number; trend: 'positive' | 'negative' }) => {
    let index = 0
    this.listItems.forEach((item, i) => {
      if (item.id === id) index = i
    })

    if (!index) return

    this.listItems[index] = {
      id,
      name: this.listItems[index].name,
      impresions: data.impresions,
      impresionsMilified: millify(data.impresions),
      trend: data.trend,
    }
  }

  setPaginationItems = (pagination: { total: number; locallyLoaded: number }) => {
    this.listItemsPagination = pagination
  }

  fetchListItems = async () => {
    try {
      if (
        this.listItemsPagination.total <= this.listItemsPagination.locallyLoaded &&
        this.listItemsPagination.total !== 0
      )
        return

      const { data, total } = await API.get({
        route: this.storeType,
        page: 1,
        pageSize: 20,
        getError: true,
      })

      this.setPaginationItems({ total, locallyLoaded: data.items.length })

      const items = data.items.map((item: any) => {
        return { id: item.id, name: item.label, impresions: -1, impresionsMilified: '-1', trend: 'positive' }
      })

      this.setListItems(items)
    } catch (e: any) {
      UtilService.openNotification({ type: 'error', message: 'Error while loading tags', description: e?.message })
    }
  }

  getCommunities = async (type?: 'snippets' | 'items') => {
    const communities: number[] = []

    if (!type || type === 'snippets') {
      this.snippets.forEach((snippet) => {
        if (snippet.author.communities) communities.push(...snippet.author.communities)
      })
    } else {
      this.openedItemData?.heatMapData.data_points.forEach((point) => {
        if (point.community_id) communities.push(parseInt(point.community_id))
      })
    }

    const filteredCommunities = communities.filter(
      (communityID: number, index: number) => communities.indexOf(communityID) === index,
    )

    const { data } = await API.get({
      route: 'community',
      filter: `community_number:in:${JSON.stringify(filteredCommunities)}`,
    })

    const communitiesData = data?.items?.map((community: any) => {
      return { id: community.community_number, name: community.name }
    })

    if (communitiesData) this.setCommunities([...this.liteCommunities, ...communitiesData])
  }

  setCommunities = (communities: { id: string; name: string }[]) => {
    this.liteCommunities = communities
  }

  setCreators = (creators: any[]) => {
    this.loadedCreators = creators
  }

  setCreatorIds = (creatorIds: string[]) => {
    this.loadedCreatorIds = creatorIds
  }

  getCreators = async (idArray: string[]) => {
    const ids = idArray.filter((id) => !this.loadedCreatorIds.includes(id))
    this.setCreatorIds([...this.loadedCreatorIds, ...ids])
    const idString = ids.join(',')

    let data

    if (idString?.length)
      ({ data } = await API.get({ route: 'channel', customAPI: process.env.REACT_APP_SEARCH_API, ids: idString }))

    if (data?.items) this.setCreators([...this.loadedCreators, ...data.items])

    this.setSnippets(
      this.snippets.map((snippet) => {
        const creator = this.loadedCreators.filter((creator: any) => {
          return creator.channel_id === snippet.channelId
        })
        return { ...snippet, author: creator[0] ? creator[0] : '' }
      }),
    )

    this.getCommunities()
  }

  getTimeSpan = () => {
    const formatDate = (ms: number) => {
      let date = new Date(ms)

      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()

      return `${year}-${month + 1}-${day}`
    }

    const daysLookup = { Yesterday: 1, 'Last 7 days': 7, 'Last 30 days': 30, 'Last 90 days': 90 }

    let currentDate = new Date().getTime()
    let pastDate = new Date(currentDate - 86400000 * (daysLookup[this.listFilter.timeSpan] || 90)).getTime()

    return {
      startDate: formatDate(pastDate),
      endDate: formatDate(currentDate),
      span: daysLookup[this.listFilter.timeSpan],
    }
  }

  fetchSnippets = async (narratives: number[], communities: number[]) => {
    try {
      const timeSpan = this.getTimeSpan()

      if (this.snippets.length >= this.totalSnippets && this.snippets.length !== 0) return

      const { data } = await monitorAPI.loadFeed(
        Math.floor(this.snippets.length / 50) + 1,
        timeSpan.span,
        narratives,
        communities,
      )

      let creators: string[] = []

      const snippets = data.items.map((snippet: any, index: number) => {
        creators.push(snippet?.channel_id)

        return {
          /* HACK: added the index so all the ids are unique */
          id: snippet.post_id + index,
          text: snippet.snippet_text,
          date: snippet.upload_date,
          riskScore: snippet.risk_score,
          views: snippet.impression_count,
          viewsMilify: millify(snippet.impression_count) || 0,
          sourceLink: snippet.post_url,
          channelId: snippet?.channel_id,
          author: {},
        }
      })
      this.setSnippetsTotal(data.total_count)

      this.updateSnippets(snippets)

      creators = creators.filter((creator, index) => creators.indexOf(creator) === index)

      await this.getCreators(creators)
    } catch (e) {
      LogService.error({ error: e, message: 'Error in fetch snippets method.' })
    }
  }

  fetchSurpriseVideos = async () => {
    try {
      const { data } = await API.get({ route: 'takeABreak', customAPI: process.env.REACT_APP_SEARCH_API })
      this.setSurpriseVideos(data)
    } catch (e) {
      UtilService.openNotification({
        type: 'error',
        message: 'Couldnt load surprise videos',
        description: "Sadly we coudn't load the surprise videos for you :(",
      })
    }
  }

  setSurpriseVideos = (surpriseVideos: SurpriseData[]) => {
    this.surpriseVideos = surpriseVideos
  }

  setListItems = (items: IListItem[]) => {
    this.listItems = items
  }

  updateSnippets = (newSnippets: ISnippet[]) => {
    this.snippets.push(...newSnippets)
  }

  setSnippetsTotal = (number: number) => {
    this.totalSnippets = number
  }

  setSnippets = (items: ISnippet[]) => {
    this.snippets = items
  }

  setSurpriseModalData = (data: SurpriseData | null) => {
    this.surpriseModalData = data
  }

  setListFilter = (filter: any) => {
    this.listFilter = filter
    //HACK:this is just to refresh the list items so the stats are deleted on every filter change
    this.setPaginationItems({ total: 0, locallyLoaded: 0 })
    this.setListItems([])
    this.fetchListItems()
    this.openItem('-1')
  }

  setSnippetsFilter = (filter: any) => {
    this.snippetsFilter = filter
  }

  showSurprise = async (state: boolean) => {
    if (state) {
      const rand = UtilService.getRandomInt({ max: this.surpriseVideos.length })
      this.setSurpriseModalData(this.surpriseVideos[rand])
      return
    }
    this.setSurpriseModalData({ title: '', url: '' })
    await this.delay(100)
    this.setSurpriseModalData(null)
  }

  resetStore = () => {
    this.listItems = []
    this.openedItemData = null
    this.snippets = []
    this.totalSnippets = 0
    this.surpriseModalData = null
    this.sourceModalData = null
    this.surpriseVideos = []
    this.listItemsPagination = { total: 0, locallyLoaded: 0 }
  }
}
