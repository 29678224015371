import { FormInstance } from 'antd'
import { API, defineNarrativeAPI } from 'api/api'
import millify from 'millify'
import { makeAutoObservable } from 'mobx'
import { ICommunityDropdown, INarrativeDropdown, NewNarrativeContent } from 'models/models'
import { UtilService } from 'services/Util/Util'

export class DefineNarrativeStore {
  formError: string = ''
  isLoadingNarrative: boolean = false
  modalData: 'notify' | 'workLater' | 'loseChanges' | undefined = undefined
  formData: any = {}

  matchingResults: number = 0

  narrativeFilterData: INarrativeDropdown[] = []
  communityFilterData: ICommunityDropdown[] = []

  videoData: any[] = []
  snippets: NewNarrativeContent[] = []

  tenant_id: string = ''

  contentLoader: boolean = false

  userSearched: boolean = false

  matchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' = 'noSearch'

  constructor() {
    makeAutoObservable(this)
  }

  pagination = {
    current: 1,
    pageSize: 25,
    showSizeChanger: false,
  }

  get videosTablePagination() {
    return {
      ...this.pagination,
      total: this.matchingResults,
      totalMillify: millify(this.matchingResults),
    }
  }

  get contentNumMillify() {
    return millify(this.matchingResults)
  }

  get videosTableData() {
    return UtilService.getTableData({ source: this.videoData, table: 'videos' })
  }

  updateMatchingResultsState = () => {
    if (this.matchingResults === 0) {
      if (!this.userSearched) {
        this.matchingResultsState = 'noSearch'
        return
      }
      this.matchingResultsState = 'noResultsBack'
      return
    }
    if (this.matchingResults > 6000000) {
      this.matchingResultsState = 'tooMany'
      return
    }
    this.matchingResultsState = 'normal'
  }

  updateUserSearched = () => {
    this.userSearched = true
  }

  resetStore = () => {
    this.formError = ''
    this.isLoadingNarrative = false
    this.modalData = undefined
    this.formData = {}
    this.setMatchingResults(0)
    this.narrativeFilterData = []
    this.communityFilterData = []
    this.videoData = []
    this.userSearched = false
  }

  setTableLoader = (state: boolean) => {
    this.contentLoader = state
  }

  resetVideoData = () => {
    this.videoData = []
  }

  cancelNarrative = () => {
    this.modalData = 'loseChanges'
  }

  closeModal = () => {
    this.modalData = undefined
  }

  updatePagination = (pagination: any) => {
    this.pagination = pagination
    this.fetchSnippets()
  }

  fetchDropdowns = async () => {
    const [narrativesDropdownData, communitiesDropdownData] = await Promise.all([
      API.get({
        page: 1,
        pageSize: 5000,
        isPromise: true,
        route: 'narrative',
      }),
      API.get({
        page: 1,
        pageSize: 5000,
        isPromise: true,
        route: 'community',
      }),
    ])
    this.setCreatorsFilter(communitiesDropdownData.data.items)
    this.setNarrativesFilter(narrativesDropdownData.data.items)
  }

  setCreatorsFilter = (data: ICommunityDropdown[]) => {
    this.communityFilterData = data
  }

  setNarrativesFilter = (data: INarrativeDropdown[]) => {
    this.narrativeFilterData = data
  }

  setFormEror = (newError: string) => {
    this.formError = newError
  }

  setIsLoadingNarratve = (isLoadingNarrative: boolean) => {
    this.isLoadingNarrative = isLoadingNarrative
  }

  setFormData = (formData: any) => {
    this.formData = formData
  }

  setMatchingResults = (matchingResults: number) => {
    this.matchingResults = matchingResults
    this.updateMatchingResultsState()
  }

  addFormData = (newFormData: any) => {
    this.formData = { ...this.formData, ...newFormData }
  }

  fetchSnippets = async () => {
    try {
      this.setTableLoader(true)

      const {
        booleanSearch: keywords_expression,
        subset: parent_narrative_id,
        focusCommunities: communities,
      } = this.formData
      const snippetsData = { tenant_id: this.tenant_id, keywords_expression, parent_narrative_id, communities }

      const res = await defineNarrativeAPI.getSnippets({ snippetsData, pagination: this.videosTablePagination })

      this.setMatchingResults(res.total_count || 0)
      this.snippets = res.items

      this.resetVideoData()
      this.getVideosData()
    } catch (e: any) {
      this.setTableLoader(false)
      UtilService.openNotification({
        type: 'error',
        message: 'Error during fetching snippets for the narrative',
        description: e.errorCode,
      })
    }
  }

  getVideosData = async () => {
    //TODO: update to the new endpioint
    // try {
    //   this.setTableLoader(true)
    //   let videoIds = this.snippets.map((snippet) => snippet.video_id)
    //   if (videoIds.length === 0) {
    //     this.setTableLoader(false)
    //     return
    //   }
    //   const resp = await commonAPI.getVideoData(videoIds)
    //   let videoIdVideoObj: {
    //     [videoId: string]: {}
    //   } = {}
    //   resp.data.forEach((video: { videoId: string }) => {
    //     videoIdVideoObj[video.videoId] = video
    //   })
    //   videoIds.forEach((videoId, index) => {
    //     if (videoIdVideoObj[videoId]) {
    //       this.addVideo({
    //         ...videoIdVideoObj[videoId],
    //         snippets: [
    //           {
    //             offsetSeconds: this.snippets[index].offset,
    //             snippet: this.snippets[index].snippet_text,
    //           },
    //         ],
    //       })
    //     }
    //   })
    //   this.setTableLoader(false)
    // } catch (e: any) {
    //   this.setTableLoader(false)
    //   UtilService.openNotification({
    //     type: 'error',
    //     message: 'Error during fetching snippets for the narrative',
    //     description: e.errorCode,
    //   })
    // }
  }

  addVideo = (video: any) => {
    this.videoData.push(video)
  }

  publishNarrative = async () => {
    try {
      const {
        name,
        description,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
        subset: parent_narrative_id,
        linkContent: related_contents,
      } = this.formData

      const resp = await defineNarrativeAPI.publishNarrative({
        name,
        type: 'keyword',
        description,
        communities,
        keywords_expression,
        parent_narrative_id,
        related_contents: related_contents ? [related_contents] : undefined,
        tenant_id: this.tenant_id,
      })

      if (resp.id) {
        this.modalData = 'notify'
      }
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: e.message.includes(409)
          ? `The Narrative with the name ${this.formData.name} already exists.`
          : 'Error during publishing the narrative',
        description: e.message,
      })
    }
  }

  saveNarrative = async () => {
    try {
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
      } = this.formData

      const resp = await defineNarrativeAPI.saveNarrative({
        name,
        description,
        related_contents: related_contents ? [related_contents] : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: 'keyword',
      })
      if (resp.id) {
        this.modalData = 'workLater'
      }
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error during saving the narrative',
        description: e.errorCode,
      })
    }
  }

  searchQuery = () => {
    this.updateUserSearched()
    this.fetchSnippets()
  }

  setTenantId = (id: string) => {
    this.tenant_id = id
  }

  enterEditMode = async (id: string, form: FormInstance<any>) => {
    try {
      const { data: narrativeData } = await API.get({ route: 'narrative', id })
      const {
        name,
        description,
        related_links: linkContent,
        parent_narratives: subset,
        communities: focusCommunities,
        boolean_query: booleanSearch,
      } = narrativeData

      form.setFieldsValue({ name, description, linkContent, subset, focusCommunities, booleanSearch })

      this.setFormData({ name, description, linkContent, subset, focusCommunities, booleanSearch })
    } catch (e) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error while fetching the narrative data.',
        description: `We couldnt load the narrative with the id:${id}.`,
      })
    }
  }

  editNarrative = async (narrativeID?: string) => {
    if (!narrativeID) return
    try {
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
      } = this.formData

      const resp = await defineNarrativeAPI.editNarrative({
        id: narrativeID,
        name,
        description,
        related_contents: related_contents ? [related_contents] : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: 'keyword',
      })
      if (resp.id) {
        this.modalData = 'workLater'
      }
    } catch (e: any) {
      UtilService.openNotification({
        type: 'error',
        message: 'Error during saving the narrative',
        description: e.errorCode,
      })
    }
  }
}
