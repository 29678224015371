import { useState } from 'react'
import { Divider, Dropdown, Menu } from 'antd'

import { ReactComponent as DownArrow } from 'assets/images/icon-dropmenu.svg'
import { Button } from 'components/common/Button/Button'
import { ReactComponent as Check } from 'assets/images/check-icon.svg'

import './MonitorListFilter.scss'

interface Props {
  elements: { [key: string]: string[] }
  setFilter: (element: any) => void
  selectedFilter: { [key: string]: string }
}

export const MonitorListFilter = ({ selectedFilter, elements, setFilter }: Props) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const [selectedLocalFilter, setSelectedLocalFilter] = useState(selectedFilter)

  const keys = Object.keys(elements)

  const elementList: any[] = []

  keys.forEach((group) => {
    elements[group].forEach((el, index) => {
      elementList.push(
        <Menu.Item
          key={group + index}
          className='c-monitor-list-filter__dropdown-item'
          onClick={() => {
            const modFilter = { ...selectedLocalFilter }

            modFilter[group] = el
            setSelectedLocalFilter(modFilter)
          }}>
          <span>{el}</span>

          {selectedLocalFilter[group] === el && <Check className='c-monitor-list-filter__dropdown-check' />}
        </Menu.Item>,
      )
    })

    elementList.push(
      <Menu.Item key={group}>
        <Divider className='c-monitor-list-filter__divider' />
      </Menu.Item>,
    )
  })

  const filterDropdown = (
    <Menu>
      {elementList}
      <Menu.Item key='buttons'>
        <div className='c-monitor-list-filter__buttons-holder'>
          <Button
            className='c-monitor-list-filter__buttons'
            type='primary-2'
            onClick={() => {
              setSelectedLocalFilter(selectedFilter)
            }}>
            Clear all
          </Button>
          <Button
            className='c-monitor-list-filter__buttons'
            type='primary-1'
            onClick={() => {
              setMenuVisible(false)
              setFilter(selectedLocalFilter)
            }}>
            Apply changes
          </Button>
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      className='c-monitor-list-filter'
      overlayClassName='c-monitor-list-filter__dropdown'
      trigger={['click']}
      placement={'bottomRight'}
      overlay={filterDropdown}
      visible={menuVisible}
      onVisibleChange={(state) => {
        setMenuVisible(state)
      }}>
      <div>
        <span className='c-monitor-list-filter--text'>SORT BY: </span>
        {keys.map((filter, index) => {
          return <span key={index}>{`${selectedFilter[filter]}${index < keys.length - 1 ? ',' : ''} `}</span>
        })}
        <DownArrow />
      </div>
    </Dropdown>
  )
}
