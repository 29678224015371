import { Button as AntdButton } from 'antd'
import classNames from 'classnames'

import { ReactComponent as IconVideoGood } from 'assets/images/video-good.svg'
import { ReactComponent as IconVideoBad } from 'assets/images/video-bad.svg'

import './Button.scss'

export const Button = ({
  type,
  children,
  labelIcon,
  ...rest
}: {
  type: 'primary-1' | 'primary-2' | 'label-default' | 'label-selected'
  children?: React.ReactNode
  labelIcon?: 'X' | 'V'
  [x: string]: any
}) => {
  return type.indexOf('label') !== -1 ? (
    <AntdButton {...rest} className={classNames(`custom-button--${type}`, { ...rest }?.className)} type='text'>
      {labelIcon === 'X' ? <IconVideoBad /> : <IconVideoGood />}
    </AntdButton>
  ) : (
    <AntdButton {...rest} className={classNames(`custom-button--${type}`, { ...rest }?.className)}>
      {children}
    </AntdButton>
  )
}
