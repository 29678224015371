import { Tag } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { IVideo } from 'models/models'

import './VideoExtra.scss'

interface Props {
  video: IVideo
}

export function VideoExtra({ video }: Props): JSX.Element {
  const { channelThumbnail, channelTitle, channelViewsMillify, channelSubsMillify, communities, channelId, platform } =
    video

  return (
    <div className='c-video-extra'>
      <a
        className='c-video-extra__channel'
        href={
          platform === 'YouTube'
            ? `https://www.youtube.com/channel/${channelId}`
            : platform === 'BitChute'
            ? `https://www.bitchute.com/channel/${channelId.replace('BitChute|', '')}/`
            : platform === 'Rumble'
            ? channelId
            : '#/'
        }
        target='_blank'
        rel='noreferrer'>
        <img className='c-video-extra__img' src={channelThumbnail} alt={channelTitle} />
        {channelTitle}
      </a>

      <p className='c-video-extra__stats'>
        {channelViewsMillify !== '0' && `${channelViewsMillify} views | `}{' '}
        {channelSubsMillify !== '0' ? channelSubsMillify : 'N/A'} subscribers
      </p>

      <p className='c-video-extra__communities'>
        {communities?.map((commnity) => (
          <Tag key={uuidv4()}>{commnity}</Tag>
        ))}
      </p>
    </div>
  )
}
