import { Input, Modal } from 'antd'
import { useState } from 'react'
interface Props {
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
  type: 'narrative' | 'tag' | 'community'
  editItem: (
    type: 'narrative' | 'tag' | 'community',
    id: string | null | undefined,
    label: string,
    afterAsyncOperation: (label: string) => void,
    setIsModalVisible: (state: boolean) => void,
  ) => Promise<void>
  item: { id: string | null | undefined; name: string | null | undefined }
  isLoading?: boolean
  afterAsyncOperation: (label: string) => void
}
export const EditModal = ({
  isVisible,
  setIsModalVisible,
  type,
  editItem,
  item,
  isLoading,
  afterAsyncOperation,
}: Props) => {
  const [inputValue, updateInputValue] = useState(item.name)

  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      title={`Edit ${type} name`}
      wrapClassName='c-share-modal'
      visible={isVisible}
      closable
      confirmLoading={isLoading}
      centered
      onOk={() => {
        if (!inputValue) return
        editItem(type, item.id, inputValue, afterAsyncOperation, setIsModalVisible)
      }}
      onCancel={() => setIsModalVisible(false)}
      okText='Update name'
      cancelText='Cancel'>
      <p className='c-share-modal__label'>{`${type} Name`}</p>
      <Input
        className='c-share-modal__user-search'
        placeholder={`Edit ${type}`}
        onChange={(el) => updateInputValue(el.target.value)}
        value={inputValue as string}
      />
    </Modal>
  )
}
