import { VIDEOS_SUMMARY_COLUMNS, MANAGE_NARRATIVES_TABLE } from './columns.settings'

export const ROUTES = {
  tag: 'tag',
  community: 'community',
  narrative: 'narrative',

  newNarrativeMatchingResults: 'getNumMatchingResults',
  newNarrativePublish: '/narrative/publish',
  newNarrativeSave: '/narrative/save',
  newNarrativeSnippetsSearch: '/snippets/search',

  getVideoData: 'getVideo',

  takeABreak: 'take-a-break',

  channel: 'channel',

  listUsers: 'listUsers',
  roles: 'getAvailableRoles',
  users: 'users',
  user: 'user',
  statistics: 'stats',
}

export const PAGINATION_SINGLE_PAGE = {
  pageSize: 10,
  current: 1,
  total: 0,
  totalMillify: '0',
  showSizeChanger: true,
  pageSizeOptions: [5, 10, 15, 20],
}

export const PAGINATION_SUMMARY = {
  current: 1,
  pageSize: 7,
  total: 0,
  totalMillify: '0',
}

export const INITIAL_DATE_INTERVAL = 3

export const FILTER_DATES = {
  '1': 'Last 30 days',
  '3': 'Last 3 months',
  '6': 'Last 6 months',
  '12': 'Last year',
  '24': 'Last 2 years',
  '36': 'Last 3 years',
}

export { VIDEOS_SUMMARY_COLUMNS, MANAGE_NARRATIVES_TABLE }
