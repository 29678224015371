import { observer } from 'mobx-react-lite'
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { SecondaryNavigation } from 'components/common/SecondaryNavigation/SecondaryNavigation'

export const ManagePage = observer(() => {
  const location = useLocation()

  const subnavigation = ['tags', 'narratives', 'communities', 'tag']

  const containsSubnav = subnavigation.some((el) => location.pathname.includes(el))

  return (
    <>
      {!containsSubnav && <Navigate to='tags' />}
      <SecondaryNavigation />
      <Outlet />
    </>
  )
})
