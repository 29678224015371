import './Creator.scss'

interface Props {
  creatorName: string
  creatorImage: string
}

export const Creator = ({ creatorName, creatorImage }: Props) => {
  return (
    <div className='c-creator'>
      <img className='c-creator__image' src={creatorImage} alt='creator logo'></img>
      <span className='c-creator__text'>{creatorName}</span>
    </div>
  )
}
