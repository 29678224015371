import { HeatMapData } from 'models/models'

import { Popover } from 'antd'
import millify from 'millify'
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useRef, useState } from 'react'

import './MonitorHeatMap.scss'

interface Props {
  data?: HeatMapData
  communities: {
    id: string
    name: string
  }[]
}
const scale = (number: number, inMin: number, inMax: number, outMin: number, outMax: number) => {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

export const MonitorHeatMap = ({ data, communities }: Props) => {
  let maxImpresions = 0
  let impresionSum = 0
  let desiredLineCount = 3
  const [communitiesComp, setCommunitiesComp] = useState<{ id: string | number; name: string }[]>([])

  const ref = useRef<null | HTMLDivElement>(null)
  const [widthToWorkWith, setWidthToWorkWith] = useState(
    ref.current ? (ref.current.offsetWidth - 20) * desiredLineCount : 0,
  )

  useEffect(() => {
    setWidthToWorkWith(ref.current ? (ref.current.offsetWidth - 20) * desiredLineCount : 0)
  }, [ref.current])

  useEffect(() => {
    if (communities.length > 0) {
      if (data?.data_points)
        setCommunitiesComp(
          data?.data_points.map((point) => {
            const res = communities.filter((el) => el.id === point.community_id)[0]
            if (res) return res
            else return { id: -1, name: 'Hidden' }
          }),
        )
    }
  }, [communities])

  if (!data?.data_points[0]) return <></>
  let minImpresions = data?.data_points[0].impression_delta

  data?.data_points.forEach((element) => {
    if (element.impression_delta > maxImpresions) maxImpresions = element.impression_delta
    if (element.impression_delta < minImpresions) minImpresions = element.impression_delta
  })

  let processedData = data?.data_points.map((el) => {
    const ratio = scale(el.impression_delta, minImpresions, maxImpresions, 0, 1)

    impresionSum += el.impression_delta
    return { ...el, ratio: ratio }
  })

  const wConst = widthToWorkWith / impresionSum
  const impresionsPerLine = impresionSum / desiredLineCount

  processedData.sort((a, b) => {
    return b.impression_delta - a.impression_delta
  })

  let groupedData = []
  let currentLineSum = 0
  let supIndex = 0

  for (let i = 0; i < processedData.length; i++) {
    currentLineSum += processedData[i].impression_delta
    if (currentLineSum >= impresionsPerLine - processedData[i].impression_delta) {
      groupedData.push(processedData.slice(supIndex, i + 1))
      supIndex = i + 1
      currentLineSum = 0
    }
  }
  groupedData.push(processedData.slice(supIndex, processedData.length))

  return (
    <div ref={ref} className='c-monitor-heat-map'>
      {groupedData?.map((group, index) => (
        <div className='c-monitor-heat-map__row' key={index}>
          {group.map((el: any) => {
            const opacity = scale(el.ratio, 0, 1, 0.15, 1)
            const textFontSize = scale(el.ratio, 0, 1, 10, 16)
            const impresionsFontSize = scale(el.ratio, 0, 1, 18, 24)

            return (
              <Popover
                key={uuidv4()}
                placement='top'
                content={
                  <>
                    <p>
                      Name:{' '}
                      {communitiesComp.filter((comp) => {
                        return comp.id === el.community_id
                      })[0]?.name || el.community_id}
                    </p>
                    <p>Impressons: {millify(el.impression_delta)}</p>
                  </>
                }
                trigger='hover'>
                <div
                  id={`${el.ratio}`}
                  className='c-monitor-heat-map__item'
                  style={{ backgroundColor: `rgba(204, 165, 90, ${opacity})`, width: wConst * el.impression_delta }}>
                  <p className='c-monitor-heat-map__item--name' style={{ fontSize: textFontSize }}>
                    {communitiesComp.filter((comp) => {
                      return comp.id === el.community_id
                    })[0]?.name || el.community_id}
                  </p>
                  <span className='c-monitor-heat-map__item--impresions' style={{ fontSize: impresionsFontSize }}>
                    {millify(el.impression_delta)}
                  </span>
                </div>
              </Popover>
            )
          })}
        </div>
      ))}
    </div>
  )
}
