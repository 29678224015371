import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function updateUserRole({ username, newRoleId }: { username: string; newRoleId: string }) {
  try {
    const { data } = await axios.put(`${process.env.REACT_APP_USER_API}${ROUTES.users}/${username}`, {
      roleId: newRoleId,
      groupIds: [],
    })

    return { data }
  } catch (error: any) {
    LogService.error({ message: 'updateUserRole', error })
    return { data: null, error: error.response?.data?.message }
  }
}
