export const generateURLSearchParams = (params: Record<string, any>) => {
  let searchParams = ''

  for (const property in params) {
    const value = params[property]
    const isVlidParam = typeof value !== 'undefined' && value !== null && value !== '' && value.length !== 0

    if (isVlidParam) {
      const param = `${property}=${window.encodeURIComponent(value)}`

      if (param.length) {
        searchParams += `${property}=${window.encodeURIComponent(value)}&`
      } else {
        searchParams += `${property}=`
      }
    }
  }

  // remove last "&"
  return searchParams.slice(0, -1)
}
